import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Cookies from 'js-cookie';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useStoreActions, useStoreState } from "easy-peasy";

import AcceptableUse from './AcceptableUse';
import APIKeys from './APIKeys';
import ContactFields from './ContactFields';
import ContactList from './ContactList';
import ContactForm from './ContactForm';
import DomainList from './DomainList';
import Home from './Home';
import Integrations from './Integrations';
import ForgotPassword from './ForgotPassword';
import UpdatePassword from './UpdatePassword';
import LogIn from './LogIn';
import LogIn2FA from './LogIn2FA';
import LogOut from './LogOut';
import MessageList from './MessageList';
import Origin from './Origin';
import Pricing from './Pricing';
import Privacy from './Privacy';
import SignUp from './SignUp';
import Terms from './Terms';
import Users from './Users';
import Subscription from './Subscription';
import VerifySignUp from './VerifySignUp';
import '../App.css';


export default function App(props) {
  const is_logged_in = useStoreState(state => state.is_logged_in);
  const domains = useStoreState(state => state.domains);
  const current_domain = useStoreState(state => state.current_domain);
  const getDomains = useStoreActions(actions => actions.domains.getDomains);
  const setCurrentDomain = useStoreActions(actions => actions.setCurrentDomain);
  const getContacts = useStoreActions(actions => actions.contact_list.getContacts);
  const getContactFields = useStoreActions(actions => actions.contact_fields.getFields);
  const getUsers = useStoreActions(actions => actions.users.getUsers);
  const getAPIKeys = useStoreActions(actions => actions.api_keys.getKeys);
  const getIntegrations = useStoreActions(actions => actions.integrations.getIntegrations);
  const getSubscriptions = useStoreActions(actions => actions.subscriptions.getSubscriptions);
  const getUser = useStoreActions(actions => actions.user.getUser);
  const active_subscription = useStoreState(state => state.subscriptions.active_subscription);
  const setIsLoggedIn = useStoreActions(actions => actions.setIsLoggedIn);


  function ToggleClass() {
     var element = document.getElementById("hamburger");
     element.classList.toggle("collapsed");
  }
  function ToggleClassShow() {
     var element = document.getElementById("nonUser");
     element.classList.toggle("show");
  }
  function ToggleClassUser() {
     var element = document.getElementById("hamburgerUser");
     element.classList.toggle("collapsed");
  }
  function ToggleClassShowUser() {
     var element = document.getElementById("userNav");
     element.classList.toggle("show");
  }

  useEffect(() => {
    console.log("IN USE EFFECT - App.js")
    if (Cookies.get('api_key') && Cookies.get('name') && Cookies.get('is_logged_in') && is_logged_in) {
      getUser();
      getSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_logged_in]);

  useEffect(() => {
    if (active_subscription) {
      console.log("GETTING STUFF")
      setIsLoggedIn(true);
      getAPIKeys();
      getUsers();
      getDomains();
      getIntegrations();
      getContactFields();
      getContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_subscription]);

  if (Cookies.get('api_key') && Cookies.get('name') && Cookies.get('is_logged_in')) {
    setIsLoggedIn(true);
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
    {is_logged_in ? '' : (
    <header id="back2top" className="position-fixed w-100">
    <Navbar expand="lg" className="navbar-dark bg-success">
      <div className="container">
        <a className="navbar-brand" href="/"><img src="images/HTMAIL_Logo_White.svg" className="logo" alt="HTMAIL" /><sup>&trade;</sup></a>
        <button id="hamburger" aria-controls="nonUser" type="button" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#nonUser">
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
        </button>
        <Navbar.Collapse id="nonUser" className="justify-content-end">
            <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link" to="/origin">Origin</NavLink>
            </li>
            <li className="nav-item">
              <NavLink onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link" to="/contact_form">Contact Us</NavLink>
            </li>
            <li className="nav-item">
              <a onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link" href="https://www.scoutware.com/" target="_blank" rel="noreferrer">Scoutware</a>
            </li>
            <li className="nav-item">
              <a onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link" href="https://linkoln.io/linkoln-email/" target="_blank" rel="noreferrer">Linkoln Email</a>
            </li>
            {/*<li className="nav-item">
              <NavLink onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link" to="/pricing">Pricing</NavLink>
            </li>
            <li className="nav-item my-2 my-lg-0">
              <NavLink onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link btn btn-light text-success py-1 px-4 px-lg-3 mt-1 ms-lg-3 d-inline d-lg-block text-uppercase" to="/sign_up">Get Started</NavLink>
            </li>
            <li className="nav-item ml-lg-3 my-3 my-lg-0">
              <NavLink onClick={function() { ToggleClass(); ToggleClassShow(); }} className="nav-link btn btn-dark text-white py-1 px-5 px-lg-4 mt-1 ms-lg-3 d-inline d-lg-block" to="/log_in">Login</NavLink>
            </li>*/}
            </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
    </header>
    )}
  {is_logged_in ? (
    <header className="position-fixed w-100">
      <Navbar className="navbar-dark bg-success" expand="lg">
        <div className="container">
          <Link className="navbar-brand" to="/"><img src="images/HTMAIL_Logo_White.svg" className="logo" alt="HTMAIL" /><sup>&trade;</sup></Link>
          <button id="hamburgerUser" aria-controls="userNav" type="button" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#userNav">
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
          </button>
          {/*<Navbar.Toggle aria-controls="userNav"/>*/}
            <Navbar.Collapse id="userNav" className="justify-content-end p-md-0">
              <Nav className="mr-0">
                <NavDropdown to="/domain" title={"Domain: " + (current_domain && current_domain.domain)}>
                  {domains.good_domains && domains.good_domains.map(domain => (
                    <NavDropdown.Item key={domain.domain} active={current_domain.domain === domain.domain} to="/changedomain" onClick={(e) => {e.preventDefault(); setCurrentDomain(domain)}}>{domain.domain}</NavDropdown.Item>
                  ))}
                </NavDropdown>
                <li className="nav-item">
                  <NavLink className="nav-link" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/messages">Messages</NavLink>
                </li>
              </Nav>
              <Nav>
                <NavDropdown as="div" title="Account">
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/account/subscription">Subscription</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/contacts">Contacts</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/contact/fields">Contact Fields</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/account/domains">Domains</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/account/users">Users</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/account/api_keys">API Keys</NavLink></NavDropdown.Item>
                  <NavDropdown.Item as="div"><a className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} href="/static/docs.html" target="_blank">API Docs</a></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/account/integrations">Integrations</NavLink></NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown as="div" title={Cookies.get('name')}>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/update_password">Manage Password</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/logout">Log Out</NavLink></NavDropdown.Item>
                  <NavDropdown.Item><NavLink className="dropdown-item" onClick={function() { ToggleClassUser(); ToggleClassShowUser(); }} to="/contact_form">Contact us</NavLink></NavDropdown.Item>
                </NavDropdown>
              </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      </header>
    ) : ''}

        <Switch>
          <Route path="/messages">
            <div className="container mb-5 py-5 mt-5">
              <MessageList/>
            </div>
          </Route>
          <Route path="/origin">
            <div className="container-fluid px-0">
              <Origin/>
            </div>
          </Route>
          <Route path="/pricing">
            <div className="container-fluid mb-5 pb-5 px-0">
              <Pricing/>
            </div>
          </Route>
          <Route path="/privacy">
            <div className="container mb-5 pb-5 px-0">
              <Privacy/>
            </div>
          </Route>
          <Route path="/terms">
            <div className="container mb-5 px-0">
              <Terms/>
            </div>
          </Route>
          <Route path="/contact_form">
            <div className="container pb-5 mt-5">
              <ContactForm/>
            </div>
          </Route>
          <Route path="/contacts">
            <div className="container pt-5 mt-5">
              <ContactList/>
            </div>
          </Route>
          <Route path="/contact/fields">
            <div className="container py-5 mt-5">
              <ContactFields/>
            </div>
          </Route>
          <Route path="/account/api_keys">
            <div className="container py-5 mt-5">
              <APIKeys/>
            </div>
          </Route>
          <Route path="/account/integrations">
            <div className="container py-5 mt-5">
              <Integrations/>
            </div>
          </Route>
          <Route path="/account/subscription">
            <Subscription/>
          </Route>
          <Route path="/account/users">
            <div className="container py-5 mt-5">
              <Users/>
            </div>
          </Route>
          <Route path="/logout">
            <div className="container py-5 mt-5">
              <LogOut/>
            </div>
          </Route>
          <Route path="/account/domains">
            <div className="container py-5 mt-5">
              <DomainList/>
            </div>
          </Route>
          <Route path="/sign_up">
            <div className="container py-5">
              <SignUp/>
            </div>
          </Route>
          <Route path="/forgot_password">
            <div className="container py-5">
              <ForgotPassword/>
            </div>
          </Route>
          <Route path="/update_password/:code" key={document.location.href}>
            <div className="container py-5 mt-5">
              <UpdatePassword/>
            </div>
          </Route>
          <Route path="/update_password">
            <div className="container py-5 my-5">
              <UpdatePassword/>
            </div>
          </Route>
          <Route path="/complete_signup/:code" key={document.location.href}>
            <div className="container py-5">
              <VerifySignUp/>
            </div>
          </Route>
          <Route path="/log_in">
            <div className="container pb-5">
              <LogIn/>
            </div>
          </Route>
          <Route path="/log_in_2fa">
            <div className="container py-5">
              <LogIn2FA/>
            </div>
          </Route>
          <Route path="/acceptableuse">
            <div className="container py-5">
              <AcceptableUse/>
            </div>
          </Route>
          <Route path="/">
            <Home/>
          </Route>
        </Switch>
        <footer className="position-relative bg-dark text-white py-5">
            <HashLink to="#back2Top" ></HashLink>
            <div className="container">
                <div className="row">
                    <div class="col-md-6 pe-md-5">
                    <p>HTMAIL is a protocol for private, sustainable, person-to-person communications where messages are driven by links and consumed in a browser.</p>
                      <h4 class="mb-3">HTMAIL is covered by:<br/>VERIFIED HYPERMEDIA COMMUNICATIONS</h4>
                      <p>US Patent. No. 11,405,346<br/>
                      US Patent. No. 11,646,988<br/>
                      All Rights Reserved.</p>
                      <h4>Please call <a href="tel:3126516626">312-651-6626</a> for HTMAIL LICENSING.</h4>
                    </div>
                    <div className="col-md-3">
                        <h4 className="mb-4">Site Links</h4>
                        <ul className="menu list-unstyled">
                          <small>
                            <li><a href="/">Home</a></li>
                            <li><Link to="/origin">Origin</Link></li>
                            <li><Link to="/contact_form">Contact Us</Link></li>
                            <li><a href="https://linkoln.io/linkoln-email/" target="_blank" rel="noreferrer">Linkoln Email</a></li>
                            <li><a href="https://www.scoutware.com/" target="_blank" rel="noreferrer">Scoutware</a></li>
                            {/*<li><Link to="/privacy">Privacy</Link></li>
                            <li><Link to="/terms">Terms</Link></li>*/}
                          </small>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4 className="mb-4">Follow Us at Linkoln</h4>
                        {/*<p><small>Sales: <a href="tel:3126516626;3">312-651-6626 Ext 3</a>
                            <br/>Support: <a href="tel:3126516626;1">312-651-6626 Ext 1</a><br/>
                            Billing: <a href="tel:3126516626;2">312-651-6626 Ext 2</a></small></p>
                        */}
                        <ul className="list-inline">
                            <li className="list-inline-item fs-3 ms-3"><a href="https://twitter.com/linkolnteam" target="_blank" rel="noreferrer" ><i className="fa-brands fa-x-twitter" aria-hidden="true"></i><span className="visually-hidden">Follow Linkoln On Twitter</span></a></li>
                            <li className="list-inline-item fs-3 ms-3"><a href="https://www.linkedin.com/company/lincolnteam/" target="_blank" rel="noreferrer" ><i class="fa-brands fa-linkedin-in"></i><span className="visually-hidden">Folluw Linkoln On LinkedIn</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </Router>
  );
}
