import { action, thunk } from 'easy-peasy';
import { getSubscriptions, getSubscriptionTypes } from '../services/Subscriptions';

const SubscriptionModel = {
  active_subscription: null,
  subscription_types: [],
  all_subscriptions: [],

  // TODO: pull out only active sub too?
  showSubscriptions: action((state, new_subscriptions) => {
    state.all_subscriptions = new_subscriptions || [];
    if (new_subscriptions && new_subscriptions.length) {
      new_subscriptions.forEach(sub => {
        if (sub.status === 'active') {
          state.active_subscription = sub;
        }
      });
    }
  }),

  showSubscriptionTypes: action((state, new_subscriptions) => {
    state.subscription_types = new_subscriptions || [];
  }),

  getSubscriptionTypes: thunk(async (actions) => {
    let types = await getSubscriptionTypes();
    actions.showSubscriptionTypes(types);
  }),

  getSubscriptions: thunk(async (actions) => {
    let subs = await getSubscriptions();
    actions.showSubscriptions(subs);
  })
};

export default SubscriptionModel;
