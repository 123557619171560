import {doPost, doGet} from './Base';


const getIntegrations = async () => {
  return await doGet('/integrations');
};


const createTwilio = async (payload) => {
  let body = {
    account_sid: payload.sid,
    token: payload.token,
    from_phone: payload.phone
  };
  return await doPost('/integrations/twilio', body);
};


export {getIntegrations, createTwilio};
export default getIntegrations;
