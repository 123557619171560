import {doPost, doGet} from './Base';


const getLinks = async(message) => {
  const url = process.env.REACT_APP_API_URL + '/message/' + message.message_id + '/links';
  const response_json = await doGet(url, true);
  return response_json.links;
}


const createLink = async(message, contact, users) => {
  const url = process.env.REACT_APP_API_URL + '/message/' + message.message_id  + '/link';
  const body = {
        contact_id: contact.contact_id,
        notify_user_ids: users.map(user => user.user_id)
    }
  await doPost(url, body, true);
}


export {getLinks, createLink};
export default getLinks;
