import React from 'react';
import { useStoreState } from "easy-peasy";
import TwilioConnect from './TwilioConnect';
import Twilio from './Twilio';


export default function Integrations() {
  const twilio = useStoreState(state => state.integrations.twilio);
  const twilio_connect = useStoreState(state => state.integrations.twilio_connect);

  const has_twilio = () => {
    if (Object.keys(twilio).length) {
      return true;
    }
    return '';
  }

  const has_twilio_connect = () => {
    if (Object.keys(twilio_connect).length) {
      return true;
    }
    return '';
  }

  return (
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
          <h3>Connect to Twilio</h3>
        </div>
        <div className="col-12">
          <p>There are two ways to connect to Twilio:</p>
          <ol>
            <li><strong>Twilio Connect: </strong> This is the easiest method and allows you to create a Twilio
                account in the process, if you don't have one already.  It allows HTMAIL to create a phone number
                on your account and send SMS messages from that number.
            </li>
            <li><strong>Twilio Auth Token: </strong> This method requires you to have an existing Twilio account and
                Twilio phone number set up for sending SMS messages.  You must log into your Twilio account and get
                your Account SID, Auth Token, and phone number to enter in the fields below.
            </li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h5>Twilio Connect</h5>
          {has_twilio_connect() &&
            <p>Twilio Connect, using phone {twilio_connect['from_phone']}</p>
          }
          {!has_twilio_connect() && !has_twilio() &&
              <TwilioConnect/>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h5>Twilio Auth Token</h5>
          {has_twilio() &&
            <p>Connected to Twilio using phone {twilio['from_phone']}</p>
          }
          {!has_twilio_connect() && !has_twilio() &&
            <Twilio service_data={twilio} />
          }
        </div>
      </div>
    </div>
  );
}
