import React, {useEffect, useState} from 'react';

import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions } from "easy-peasy";

function ContactForm(props) {
  const [email, setEmail] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const submitContactForm = useStoreActions(actions => actions.submitContactForm);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    }
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LexQzEbAAAAAKsuhG9xChZB2Q7UAoI8dhjGfyUk`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute('6LexQzEbAAAAAKsuhG9xChZB2Q7UAoI8dhjGfyUk', {action: 'submit'}).then(function(token) {
        submitContactForm({first_name, last_name, email, message, token})
        .then(response => {
          if (response.success) {
            window.alert("Thank you for contacting us. Someone will respond via email soon.");
            props.history.push('/');
          } else if (response.errors) {
            window.alert(response.errors);
          }
        });
      });
    });
  };

  return (
    <>

      <div className="row pt-5">
        <div className="col-12">
        <h1 className="text-center">Contact Us</h1>
        <p className="text-center">Please drop us a line!</p>
        </div>
      </div>
    <Form id="signup" className="row d-flex justify-content-center mx-3" onSubmit={handleSubmit}>
      <div className="col-12 col-md-6 mx-auto bg-white shadow-green rounded py-3 m-3 m-sm-0">
        <Form.Group className="col-12">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="input" onChange={(e) => setFirstName(e.target.value)} value={first_name}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="input" onChange={(e) => setLastName(e.target.value)} value={last_name}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Email Address</Form.Label>
          <Form.Control type="input" onChange={(e) => setEmail(e.target.value)} value={email}/>
        </Form.Group>
        <Form.Group className="col-12 formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" onChange={(e) => setMessage(e.target.value)} value={message}/>
        </Form.Group>
        <div className="col-12">
          <Button type="submit" variant="success" size="lg" className="w-100 text-uppercase mt-4">Submit</Button>
        </div>
      </div>
    </Form>
    <div className="row pt-5">
      <div className="col-12">
        <p className="text-center">Your email address will be used only for the purpose of replying to your inquiry.</p>
      </div>
    </div>
    </>
  );
}

export default withRouter(ContactForm);
