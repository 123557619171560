import React, {useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';


function Pricing (props) {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <>
        {/*<div className="container pt-5 mt-5">
          <div className="row">
              <div className="col text-center">
                  <h1 className="text-uppercase">Pricing</h1>
              </div>
          </div>
        </div>*/}

        <section>
          <div className="container mt-5 py-5">
          <div className="row d-flex">
              <div className="col-md-5 mx-auto shadow mb-4 mb-md-0 p-3 p-md-4 rounded-3 bg-dark text-white border border-light border-2">
                  <h2 className="mb-4">Nine Dollar Alphas.</h2>
                  <ul className="checklist p-0">
                    <li className="h5">Your own private developer account</li>
                    <li className="h5">Dedicated personal domain name.</li>
                    <li className="h5">The High Ground of Online Correspondence.</li>
                  </ul>
                  <p className="h5 text-success text-uppercase mb-0">All just $9 per month.</p>
                  <p className="text-muted fst-italic"><small>Alpha pricing is only available for a limited time. Setup fee may apply for personal domains. </small></p>
              </div>
              <div className="col-md-5 mx-auto shadow mb-4 mb-md-0 p-3 p-md-4 rounded-3 bg-dark text-white border border-light border-2">
                  <h2 className="mb-4">Check This Out...</h2>
                  <ul className="checklist p-0">
                  <li className="h5">Full API access.</li>
                  <li className="h5">30 days free. </li>
                  <li className="h5">No credit card required.</li>
                  </ul>
                  <Link to="/sign_up" className="btn btn-success btn-lg text-uppercase px-5" role="button">Get Started</Link>
              </div>
          </div>
          </div>
        </section>

      </>
    );
}

export default withRouter(Pricing);
