import React, {useEffect} from 'react';


function Privacy (props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return (
        <div className="container pt-5 mt-5">
          <div className="row pt-5">
              <div className="col">
              <h1>SCOUTWARE, LLC PRIVACY STATEMENT</h1>
              <p className="h3">PLEASE READ THIS PRIVACY STATEMENT CAREFULLY.</p>

              <p>Your privacy is very important to Scoutware. We also understand that privacy is very important to you. This Privacy Statement ("Privacy Statement") tells you how we protect and use any information we gather through this website located at Scoutware.com. This website is operated by Scoutware, LLC and their affiliates (hereafter referred to as "Scoutware").</p>
              <p>By using this Site, you agree to the terms of this Privacy Statement. Please read our Terms of Use to understand the general rules about your use of this Site. Except as written in any other disclaimers, policies, terms of use, or other notices on this Site, this Privacy Statement and the Terms of Use are the complete agreement between you and Scoutware with respect to your use of this Site. You may be subject to additional terms that may apply when you access particular services or materials on certain areas of this Site, or by following links that take you away from this Site.</p>

              <h2>INTRODUCTION</h2>
              <p>The information we receive, and how we use it, depends on what you do when visiting our Site. We collect and use your non-personal information (information that is not identifiable to you personally) differently than your personal information.</p>

              <h3>WHAT IS PERSONAL INFORMATION?</h3>
              <p>In some places on this Site you may have the chance to send us information about yourself. For example, our Site may include a registration form, surveys or e-mail forms. Most of our services do not require any form of registration, allowing you to visit our site without telling us who you are. However, some services may require registration. When you register with us, you may need to complete certain fields (some are required and some are optional), as well as choose a user name and password. In these situations, if you choose to withhold any personal data requested by us, it may not be possible for you to gain access to certain parts of the site and for us to respond to your words.</p>
              <p>The personal information that we collect if you fill out such forms or surveys, or when you ask us to personalize your visits to our Site, may include, for example:</p>
              <ul>
              <li>your name</li>
              <li>phone number</li>
              <li>address</li>
              <li>e-mail address</li>
              <li>other personal information such as your business, school or college affiliation.</li>
              </ul>

              <h3>HOW DOES SCOUTWARE USE PERSONAL INFORMATION?</h3>
              <p>Scoutware may use the personal information we collect to:</p>
              <ul>
              <li>respond to your requests</li>
              <li>contact you, via e-mail, regular mail, telephone or otherwise</li>
              <li>to provide you with information that we believe may be of interest to you</li>
              <li>to develop records, including records of your personal information</li>
              <li>to develop and improve programs, products, services and content</li>
              <li>to enforce this Terms of Use and Privacy Statement and other rules about your use of this site</li>
              <li>to protect Scoutware's rights or property</li>
              <li>to protect someone's health, safety or welfare</li>
              <li>to comply with a law or regulation, court order or other legal process.</li>
              <li>By submitting personal information (such as your name, address, e-mail address, telephone number, etc.), you agree that we may keep a record of it and may use it in accordance with the above.</li>
              </ul>

              <h3>SHARING PERSONAL INFORMATION WITH OTHERS</h3>
              <p>Scoutware will not share, rent, lease, or sell your personal information to others at any time, for any reason.</p>

              <h3>SERVICE-RELATED ANNOUNCEMENTS</h3>
              <p>We will send our clients service-related announcements on occasions when it is necessary to do so. For instance, notifications are sent both before and after a client site is upgraded, and to notify clients of planned outages and/or change windows. Also, automated service emails are available, on strictly an opt-in basis, to inform users regarding daily activity levels, such as traffic reports, email bounces, and more.</p>
              <p>Generally, the site administrator may not opt-out of service-related communications, which are not promotional in nature. If you do not wish to receive them, you can choose to opt-out of future service-related communications as long as another adminstrative user is registered to your client site.</p>

              <h3>PROMOTIONAL ANNOUNCEMENTS</h3>
              <p>From time to time we will send out emails to our customer which are promotional in nature. Examples may include information regarding new features and available tools, announcements of instructional or informational webcasts, invitations to client appreciation events, etc. If you no longer wish to receive these non-service related communications, you may opt-out of receiving them by following the opt-out instructions included in each Scoutware email, by emailing us at <a href="mailto:cs@scoutware.com">cs@scoutware.com</a>, or by calling us at <a href="tel:312-651-6626">312-651-6626.</a></p>

              <h3>ACCESS</h3>
              <p>All client users can access and edit their own PII directly through their client site. To update, delete your information, make your changes on the edit page under your staff profile within your Scoutware site or by emailing our Customer Support at cs@scoutware.com or by contacting us by telephone or postal mail at the contact information listed below.</p>

              <p>We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

              <h3>IS SCOUTWARE EVER REQUIRED TO SHARE PERSONAL INFORMATION WITH THIRD PARTIES?</h3>
              <p>Except as otherwise written in this Privacy Statement, we will keep your personal information private and will not share it with others, unless the disclosure is necessary to:</p>
              <ul>
              <li>comply with a law or regulation, court order, subpoena or other legal process</li>
              <li>protect our rights or property</li>
              <li>enforce our Terms of Use or this Privacy Statement</li>
              <li>protect someone's health or safety.</li>
              <li>if Scoutware is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</li>
              </ul>
              <p>In addition, you are prohibited from posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity</p>

              <h3>WHAT ARE TRACKING TECHNOLOGIES</h3>
              <p>As is true of most web sites, we gather certain information automatically and store it in log files. This information may include Internet protocol (IP) addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data.</p>

              <p>We do not link this automatically collected data to other information we collect about you.  Or We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, to improve marketing, analytics, or site functionality.</p>

              <p>Technologies such as: cookies, beacons, tags and scripts are used by Scoutware and our partners [specify type e.g. marketing partners], affiliates, or analytics or service providers  [specify type e.g. online customer support provider, etc.]. These technologies are used in analyzing trends, administering the site, tracking users’ movements around the site and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual as well as aggregated basis.</p>

              <p>We use cookies for [our shopping cart, to remember users’ settings (e.g. language preference), for authentication]. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited.</p>

              <h3>SECURITY</h3>
              <p>The security of your personal information is important to us. When you enter information on our inquiry forms, we encrypt that information using secure socket layer technology (SSL). To learn more about SSL, follow this link:</p>

              <p><a href="http://www.thawte.com/ssl-encryption/digital-certificates.html">http://www.thawte.com/ssl-encryption/digital-certificates.html</a></p>

              <p>We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

              <p>If you have any questions about security on our Web site, you can send email us at <a href="mailto:support@scoutware.com">support@scoutware.com</a></p>

              <h3>WHAT ABOUT PRIVACY ON OTHER SITES?</h3>
              <p>This Site may contain links to other websites. We provide these links for your convenience, but we do not review, control, or monitor the privacy practices of websites operated by others. Scoutware is not responsible for the performance of these sites or for your business dealings with them. Your use of any other websites is subject to the terms and conditions of those sites, including the privacy policies of those sites.</p>

              <p>Furthermore, this Privacy Statement does not necessarily reflect the privacy practices of our customers and Scoutware, LLC is not responsible for its customers' privacy policies or practices. Scoutware, LLC does not review, comment upon or monitor its customers' compliance with their respective privacy policies, nor does Scoutware, LLC review its customer instructions to determine whether they are in compliance with or conflict with the terms of a customer's published privacy policy.</p>

              <p>As an ASP, Scoutware, LLC operates as a data processor for our customers. Our customers are the data collectors and controllers of the data.</p>

              <h3>ARE THERE SPECIAL RULES ABOUT CHILDREN'S PRIVACY?</h3>
              <p>We care about protecting the online privacy of children. We will not intentionally collect any personal information (such as a child's name or e-mail address) from children under the age of 13. If you think that we have collected personal information from a child under the age of 13, please contact us.</p>

              <p>This site is not intentionally designed for or directed at children 13 years of age or younger without obtaining prior consent from a person with parental responsibility. It is our policy never to knowingly collect or maintain information about anyone under the age of 13 (such as a child's name or e-mail address). Please contact us if you think we may have collected information from a child under the age of 13.</p>

              <h3>A NOTE TO RECIPIENTS OF EMAIL MESSAGES FROM SCOUTWARE USERS</h3>
              Scoutware does not monitor or limit our users from creating email messages. We do, however, quickly and fervently respond to notification of violations or abuses of Scoutware Terms of Use, including failure to honor opt-outs.

              <h3>CHANGES IN THIS PRIVACY STATEMENT</h3>
              <p>This Privacy Statement was last modified on August 1, 2021. Scoutware reserves the right to change this Privacy Statement at any time and for any reason. We encourage you to look to this Privacy Statement each time you visit the Site and especially before you provide any personally identifiable information.</p>

              <p>If we decide to change our privacy policy, we will post those changes to this privacy statement, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.</p>

              <p>We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our home page prior to the change becoming effective.</p>

              <h3>CONTACT US</h3>
              <p>Scoutware, LLC.<br/>
              954 W. Washington Blvd.<br/>
              Suite 640<br/>
              Chicago, IL 60607<br/>
              <a href="tel:312-651-6626">312-651-6626</a></p>

              </div>
          </div>
        </div>
    );
}

export default Privacy;
