import DomainListModel from './DomainListModel';
import ContactListModel from './ContactListModel';
import ContactFieldsModel from './ContactFieldsModel';
import DomainModel from './DomainModel';
import UsersModel from './UsersModel';
import IntegrationsModel from './IntegrationsModel';
import APIKeysModel from './APIKeysModel';
import MessageListModel from './MessageListModel';
import LinksModel from './LinksModel';
import SubscriptionModel from './SubscriptionModel';
import ReportsModel from './ReportsModel';
import PasswordModel from './PasswordModel';
import UserModel from './UserModel';
import {logIn, signUp, logIn2FA, verify} from '../services/LogIn';
import {sendContactForm} from '../services/ContactForm';
import { action, actionOn, thunk } from 'easy-peasy';


const AppModel = {
  is_logged_in: false,

  api_keys: APIKeysModel,
  contact_fields: ContactFieldsModel,
  contact_list: ContactListModel,
  current_domain: DomainModel,
  domains: DomainListModel,
  subscriptions: SubscriptionModel,
  integrations: IntegrationsModel,
  messages: MessageListModel,
  message_links: LinksModel,
  password: PasswordModel,
  reports: ReportsModel,
  users: UsersModel,
  user: UserModel,

  onGetDomains: actionOn(
    actions => actions.domains.getDomains,
    (state, target) => {
      if (!state.current_domain.domain && state.domains.good_domains.length) {
        state.current_domain = state.domains.good_domains[0];
      }
    }
  ),
  setIsLoggedIn: action((state, is_logged_in) => {
    if (state.is_logged_in !== is_logged_in) {
      state.is_logged_in = is_logged_in;
    }
  }),
  setInitialDomain: action((state, domain) => {
    if (!state.current_domain.domain) {
      state.current_domain = domain;
    }
  }),
  setCurrentDomain: action((state, domain) => {
    if (state.current_domain.domain !== domain.domain) {
      state.current_domain = domain;
    }
  }),
  logIn: thunk(async (actions, payload) => {
    const response = await logIn(payload.username, payload.password);
    if (response.success && response.browser_verified) {
      actions.setIsLoggedIn(true);
    }
    return response;
  }),
  logIn2FA: thunk(async (actions, payload) => {
    const response = await logIn2FA(payload.code, payload.trust_browser);
    if (response.success) {
      actions.setIsLoggedIn(true);
      actions.setUserPhone(response.phone);
    }
    return response;
  }),
  signUp: thunk(async (actions, payload) => {
    console.log("IN THUNK");
    const response = await signUp(payload.email, payload.phone, payload.first_name, payload.last_name);
    return response;
  }),
  verifyAccount: thunk(async (actions, payload) => {
    const response = await verify(payload.code, payload.password);
    return response;
  }),
  submitContactForm: thunk(async (actions, payload) => {
    const response = await sendContactForm(payload);
    return response;
  }),
}

export default AppModel;

