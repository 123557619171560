import { action, thunk } from 'easy-peasy';
import {getIntegrations, createTwilio} from '../services/Integrations';

const IntegrationsModel = {
  twilio: {},
  twilio_connect: {},
  showIntegrations: action((state, new_integrations) => {
    new_integrations.forEach(new_integration => {
      if (new_integration.service === 'twilio_connect') {
        state.twilio_connect = new_integration.service_data;
      } else if (new_integration.service === 'twilio') {
        state.twilio = new_integration.service_data;
      }
    });
  }),
  getIntegrations: thunk(async (actions) => {
    let new_integrations = await getIntegrations();
    actions.showIntegrations(new_integrations);
  }),
  createTwilio: thunk(async (actions, payload) => {
    await createTwilio(payload);
    await actions.getIntegrations();
  })
};

export default IntegrationsModel;
