import {doPost, doGet} from './Base';


const getContacts = async () => {
    const response_json = await doGet(process.env.REACT_APP_API_URL + '/contacts', true);
    return response_json["contacts"];
};


const saveContact = async (contact) => {
  let url = process.env.REACT_APP_API_URL + '/contact/' + contact.contact_id;

  return await doPost(url, {contact_data: contact.contact_data}, true);
};


const getContactFields = async () => {
    return await doGet(process.env.REACT_APP_API_URL + '/contact/fields', true);
};


const createContactField = async (field) => {
  return await doPost(process.env.REACT_APP_API_URL + '/contact/fields/' + field, {}, true);
};


export {getContacts, saveContact, getContactFields, createContactField};
export default getContacts;
