import {doPost, doGet} from './Base';


const getDomains = async () => {
    return await doGet('/domains', true);
};


const checkDomain = async (domain) => {
  return await doPost('/domains/' + domain.domain + '/check', true);
};


const createDomain = async (domain) => {
  return await doPost('/create_domain', {domain: domain}, true);
};


export {getDomains, checkDomain, createDomain};
export default getDomains;
