import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useStoreActions, useStoreState } from "easy-peasy";

import Domain from './Domain';


export default function DomainList() {
  const [domain_name, setDomainName] = useState('');
  const domains = useStoreState(state => state.domains);
  const createDomain = useStoreActions(actions => actions.domains.createDomain);

  const addNewDomain = (e) => {
    e.preventDefault();
    if (domain_name) {
      createDomain(domain_name);
    }
  };

  return (
    <div className="container px-0">
      <div className="row">
        <div className="col-12">
        <h3>Domains</h3>
          <Table striped bordered responsive className="bg-white">
            <thead className="thead-dark">
                <tr><th>Domain</th><th>Status</th><th>&nbsp;</th></tr>
            </thead>
            <tbody>
            {domains.domains &&
              domains.domains.map(domain => (
                <Domain key={domain.domain} domain={domain}/>
              ))
            }
            </tbody>
          </Table>
          <hr/>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          <Form onSubmit={addNewDomain}>
            <Row>
              <Col>Add Domain:</Col>
            </Row>
            <Row>
              <Col>
                  <Form.Control as="input" onChange={(e) => setDomainName(e.target.value)} value={domain_name}/>
              </Col>
              <Col>
                <Button variant="primary my-3" onClick={addNewDomain}>Add</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}
