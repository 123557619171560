import { action, thunk } from 'easy-peasy';
import getUsers from '../services/Users';

const UsersModel = {
  users: [],
  showUsers: action((state, users) => {
    state.users = users.users;
  }),
  getUsers: thunk(async (actions) => {
    let users = await getUsers();
    actions.showUsers(users);
  })
};

export default UsersModel;
