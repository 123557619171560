import React from 'react';
import { useStoreActions } from "easy-peasy";
import {format_date} from "../Utils";


export default function Message(props) {
  const selectMessage = useStoreActions(actions => actions.messages.selectMessage);

  const showMessageLinks = (e) => {
    e.preventDefault();
    props.showLinksFor(props.message);
  }

  const showSend = (e) => {
    e.preventDefault();
    props.showSendFor(props.message);
  }

  const showReports = (e) => {
    e.preventDefault();
    props.showReportsFor(props.message);
  }

  return (
    <tr>
        <td><button onClick={() => selectMessage(props.message)} >Edit</button></td>
        <td>{ props.message.title }</td>
        <td>{ format_date(props.message.updated) }</td>
        <td><a href={process.env.REACT_APP_BACKEND_URL + "/preview/" + props.message.message_id} target="_blank" rel="noreferrer">Preview</a></td>
        <td><button onClick={showMessageLinks} >Links</button></td>
        <td><button onClick={showReports} >Reports</button></td>
        <td><button onClick={showSend} >Send</button></td>
    </tr>
  );
}
