import {doGet} from './Base';


const getMessageViews = async(message) => {
  const url = process.env.REACT_APP_API_URL + '/reports/' + message.message_id + '/views?limit=20';
  const response_json = await doGet(url, true);
  return response_json.views;
}

export default getMessageViews;
