import { thunk } from 'easy-peasy';

import {setPassword, resetPassword} from '../services/Password';

const PasswordModel = {
  setPassword: thunk(async (actions, payload, helpers) => {
    const response = await setPassword(payload.password, payload.code);
    if (response.success) {
      helpers.getStoreActions().setIsLoggedIn(true);
    }
    return response;
  }),
  resetPassword: thunk(async (actions, payload) => {
    const response = await resetPassword(payload.username);
    return response;
  }),
}

export default PasswordModel;
