import { createStore } from 'easy-peasy';
import AppModel from '../models';
import APIKeyService from '../services/APIKeys';


const store = createStore(AppModel, {
  injections: {
    APIKeyService,
  }
});

export default store;
