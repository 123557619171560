import { action, thunk } from 'easy-peasy';
import getMessageViews from '../services/Reports';

const ReportsModel = {
  message_views: [],
  setMessageViews: action((state, new_views) => {
    state.message_views = new_views;
  }),
  getMessageViews: thunk(async (actions, message) => {
    if (!message.message_id) {
        actions.setMessageViews([]);
        return;
    }
    let views = await getMessageViews(message);
    actions.setMessageViews(views || []);
  })
};

export default ReportsModel;
