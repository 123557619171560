import React from 'react';

const pricing = () => {
    return (
      <>
        <div className="container pt-5">
          <div className="row pt-5">
              <h1>Acceptable Use Policy</h1>
              <div className="col">
              <p>This Acceptable Use Policy ("<em>AUP</em>") describes rules that apply to any party ("<em>Customer</em>") using any products and services ("<em>Services</em>") provided by Scoutware, LLC. or any of its affiliates (collectively, "<em>Scoutware</em>") and any user of any software application or service made available by Customer that interfaces with the Services ("<em>End User</em>"). The examples described in this AUP are not exhaustive. Customer is responsible for its End Users' compliance with this AUP. If Customer or any End User violates this AUP, Scoutware may suspend Customer’s use of the Services. This AUP may be updated by Scoutware from time to time upon reasonable notice, which may be provided via Customer’s account, e-mail, or by posting an updated version of this AUP at WEB ADDRESS</p>
              <p>No Inappropriate Content or Users. Do not use the Services to transmit or store any content or communications (commercial or otherwise) that is illegal, harmful, unwanted, inappropriate, objectionable, confirmed to be criminal misinformation, or otherwise poses a threat to the public. This prohibition includes use of the Services by a hate group or content or communications that originate from a hate group or are exploitive, abusive, or hate speech.</p>
              <p>Prohibited Activities. Do not use the Services to engage in or encourage any activity that is illegal, deceptive, harmful, violating others’ rights, or harmful to Scoutware’s business operations or reputation, including:</p>
              <ul>
              <li>Violations of Laws. Violating laws, regulations, governmental orders, or industry standards or guidance in any applicable jurisdiction (collectively, "Applicable Laws"). This includes violating Applicable Laws requiring (a) consent be obtained prior to transmitting, recording, collecting, or monitoring data or communications or (b) compliance with opt-out requests for any data or communications.</li>
              <li>Interference with the Services. Interfering with or otherwise negatively impacting any aspect of the Services or any third-party networks that are linked to the Services.</li>
              <li>Reverse Engineering. Reverse engineering, copying, disassembling, or decompiling the Services.</li>
              <li>Falsification of Identity or Origin. Creating a false identity or any attempt to mislead others as to the identity of the sender or the origin of any data or communications.</li>
              </ul>
              <p>No Service Integrity Violations. Do not violate the integrity of the Services, including:</p>
              <ul>
              <li>Bypassing Service Limitations. Attempting to bypass, exploit, defeat, or disable limitations or restrictions placed on the Services.</li>
              <li>Security Vulnerabilities. Finding security vulnerabilities to exploit the Services or attempting to bypass any security mechanism or filtering capabilities.</li>
              <li>Disabling the Services. Any denial of service (DoS) attack on the Services or any other conduct that attempts to disrupt, disable, or overload the Services.</li>
              <li>Harmful Code or Bots. Transmitting code, files, scripts, agents, or programs intended to do harm, including viruses or malware, or using automated means, such as bots, to gain access to or use the Services.</li>
              <li>Unauthorized Access. Attempting to gain unauthorized access to the Services.</li>
              </ul>
              <p>Data Safeguards. Customer is responsible for determining whether the Services offer appropriate safeguards for Customer’s use of the Services, including, but not limited to, any safeguards required by Applicable Laws, prior to transmitting or processing, or prior to permitting End Users to transmit or process, any data or communications via the Services.</p>
              <p>Violations of this AUP, including any prohibited content or communications, may be reported to EMAIL ADDRESS. Customer agrees to immediately report any violation of this AUP to Scoutware and provide cooperation, as requested by Scoutware, to investigate and/or remedy that violation.</p>
              </div>
          </div>
        </div>
      </>
    );
}

export default pricing;
