import { action, thunk, computed } from 'easy-peasy';
import { getDomains, createDomain, checkDomain } from '../services/Domains';

const DomainListModel = {
  domains: [],
  good_domains: computed(
    state => state.domains.filter(domain => domain.status === 'good')
  ),
  checkDomain: thunk(async (actions, domain) => {
    await checkDomain(domain);
    await actions.getDomains();
  }),
  showDomains: action((state, new_domains) => {
    const existing = new Set(state.domains.map(domain => domain.domain));
    const new_ = new Set(new_domains.map(domain => domain.domain));
    if (existing !== new_) {
      state.domains = new_domains;
    }
  }),
  getDomains: thunk(async (actions, payload, {getStoreActions}) => {
    let domains = await getDomains();
    getStoreActions().setInitialDomain(domains[0])
    actions.showDomains(domains);
  }),
  createDomain: thunk(async (actions, domain) => {
    await createDomain(domain);
    await actions.getDomains();
  })
};

export default DomainListModel;
