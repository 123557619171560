import React, {useState} from 'react';

import { withRouter, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions, useStoreState } from "easy-peasy";

function UpdatePassword(props) {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const setPassword = useStoreActions(actions => actions.password.setPassword);
  const setIsLoggedIn = useStoreActions(actions => actions.setIsLoggedIn);
  const is_logged_in = useStoreState(state => state.is_logged_in);
  const {code} = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password1 !== password2){
      window.alert("Your password entries did not match each other.");
      return;
    }
    setPassword({password: password1, code: code})
    .then(response => {
      if (response.success) {
        window.alert("Success!");
        if (!is_logged_in) {
            setIsLoggedIn(true);
        }
        props.history.push("/");
      } else if (response.errors) {
        window.alert(response.errors);
      }
    });
  };

  return (
    <>
    <div className="col-12">
      {is_logged_in ? (
      <h1 className="text-center">Set Password</h1>
      ) : (
      <h1 className="text-center">Reset Password</h1>
      )}
    </div>
    <Form id="signup" className="row d-flex justify-content-center mx-3" onSubmit={handleSubmit}>
      <div className="col-12 col-md-6 mx-auto bg-white shadow-green rounded py-3 m-3 m-sm-0">
        <Form.Group className="col-12">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" onChange={(e) => setPassword1(e.target.value)} value={password1}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Re-enter Password</Form.Label>
          <Form.Control type="password" onChange={(e) => setPassword2(e.target.value)} value={password2}/>
        </Form.Group>
        <div className="col-12">
          <Button type="submit" variant="success btn-lg w-100 text-uppercase">Submit</Button>
        </div>
      </div>
    </Form>
    </>
  );
}

export default withRouter(UpdatePassword);
