import { action, thunk } from 'easy-peasy';
import { getContacts, saveContact } from '../services/Contacts';

const ContactListModel = {
  contacts: [],
  selected_contact: {},
  selectContact: action((state, contact) => {
    state.selected_contact = {...contact};
  }),
  showContacts: action((state, new_contacts) => {
    state.contacts = new_contacts;
  }),
  getContacts: thunk(async (actions) => {
    let contacts = await getContacts();
    actions.showContacts(contacts);
  }),
  saveContact: thunk(async (actions, contact) => {
    await saveContact(contact);
    await actions.getContacts();
  })
};

export default ContactListModel;
