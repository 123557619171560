const format_date = (date) => {
  if (!date) {
    return '';
  }
  let dt = new Date(date);
  let offset = new Date().getTimezoneOffset();
  dt.setMinutes(dt.getMinutes() - offset);

  dt = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  }).format(dt);
  return dt;
}

export {format_date};
