import { action, thunk } from 'easy-peasy';
import { getLinks, createLink } from '../services/Links';

const LinksModel = {
  links: [],
  showLinks: action((state, new_links) => {
    state.links = new_links;
  }),
  createLinks: thunk(async (actions, {message, contacts, users}) => {
    contacts.forEach(async (contact) => {
      await createLink(message, contact, users);
    });
  }),
  getLinks: thunk(async (actions, message) => {
    if (!message.message_id) {
        actions.showLinks([]);
        return;
    }
    let links = await getLinks(message);
    actions.showLinks(links || []);
  })
};

export default LinksModel;
