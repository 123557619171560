import { action, thunk } from 'easy-peasy';
import { getMessages, saveMessage, sendMessage } from '../services/Messages';

const MessageListModel = {
  messages: [],
  selected_message: {},
  showMessages: action((state, new_messages) => {
    state.messages = new_messages || [];
  }),
  selectMessage: action((state, message) => {
    state.selected_message = {...message};
  }),
  saveMessage: thunk(async (actions, message) => {
    await saveMessage(message);
    await actions.getMessages();
  }),
  sendMessage: thunk(async (actions, {message_text, links}) => {
    await sendMessage(message_text, links);
  }),
  getMessages: thunk(async (actions, domain, {getStoreState}) => {
    if (!domain) {
      let state = getStoreState();
      domain = state.current_domain.domain;
    }
    let messages = await getMessages(domain);
    actions.showMessages(messages.messages);
  })
};

export default MessageListModel;
