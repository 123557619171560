import Cookies from 'js-cookie';


const _checkResponse = async (response, is_api) => {
  if (response.status === 401 || response.status === 403) {
      if (is_api) {
        return;
      } else {
        window.location.href=process.env.REACT_APP_BACKEND_URL + "/logout";
        return;
      }
  } else if (response.ok) {
    const response_json = await response.json();
    return response_json;
  }
  console.log("Network error, got response code " + response.status);
  alert("The application has encountered a problem. Please try again later");
};


const doPut = async (url, params, is_api=false) => {
  const options = {
    method: "PUT",
    body: JSON.stringify(params),
    credentials: "include"
  };
  if (is_api) {
    options["headers"] = {'X-API-KEY': Cookies.get('api_key')};
  }
  const response = await fetch(url, options);
  return _checkResponse(response, is_api);
};


const doPost = async (url, params, is_api=false) => {
  const options = {
    method: "POST",
    body: JSON.stringify(params),
    credentials: "include"
  };
  if (is_api) {
    options["headers"] = {'X-API-KEY': Cookies.get('api_key')};
  }
  const response = await fetch(url, options);
  return _checkResponse(response, is_api);
};

const doGet = async (url, is_api=false) => {
  const options = {
    credentials: "include"
  }
  if (is_api) {
    options["headers"] = {'X-API-KEY': Cookies.get('api_key')};
  }
  const response = await fetch(url, options)
  return _checkResponse(response, is_api);
}

export default doGet;
export {doPost, doGet, doPut};
