import {doPost} from './Base';


const sendContactForm = async (params) => {
  return await doPost('/contact_us', params);
};


export {sendContactForm};
export default sendContactForm;
