import { action, thunk } from 'easy-peasy';
import getUser from '../services/User';

const UserModel = {
  first_name: '',
  last_name: '',
  stripe_id: '',
  phone: '',
  email: '',

  showUser: action((state, user) => {
    state.first_name = user.first_name;
    state.last_name = user.last_name;
    state.stripe_id = user.stripe_id;
    state.email = user.email;
    state.phone = user.phone;
  }),

  getUser: thunk(async (actions) => {
    let user = await getUser();
    actions.showUser(user);
  })
};

export default UserModel;
