import React, {useState, useEffect} from 'react';

import { withRouter, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions, useStoreState } from "easy-peasy";

function VerifySignUp(props) {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const verifyAccount = useStoreActions(actions => actions.verifyAccount);
  const setIsLoggedIn = useStoreActions(actions => actions.setIsLoggedIn);
  const is_logged_in = useStoreState(state => state.is_logged_in);
  const {code} = useParams();

  useEffect(() => {
    if (is_logged_in) {
      props.history.push("/");
    }
  }, [is_logged_in, props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== password2){
      window.alert("Your password entries did not match each other.");
      return;
    }
    console.log("Verifying account using code " + code);
    verifyAccount({code, password})
    .then(response => {
      if (response.success) {
        window.alert("Your account has been created!");
        setIsLoggedIn(true)
      } else if (response.errors) {
        window.alert(response.errors);
      }
    });
  };

  return (
    <>

     <div className="row text-center pt-5 mt-5">
        <div className="col-12">
          <h1 className="font-weight-bold">Let's get <span className="text-success">started.</span></h1>
          <p>Your contact information has been verified.  To complete your account, create a password below.  Passwords
            must have at least one of: lowercase letter, uppercase letter, number, and symbol.</p>
        </div>
      </div>
    <Form id="signup" className="row mx-3 d-flex justify-content-center" onSubmit={handleSubmit}>
      <div className="col-12 col-md-6 mx-auto bg-white shadow-green rounded py-3 m-3 m-sm-0">
        <div className="col-12">
          <img src="images/HTMAIL_airplaneIcon.png" className="icon-send d-block mx-auto my-3" alt="Sign Up" />
        </div>
        <Form.Group className="col-12">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" className="pr-password" onChange={(e) => setPassword(e.target.value)} value={password}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Re-enter password</Form.Label>
          <Form.Control type="password" onChange={(e) => setPassword2(e.target.value)} value={password2}/>
        </Form.Group>
        <div className="col-12">
          <Button type="submit" variant="success btn-lg w-100 text-uppercase">Start using HTMAIL</Button>
        </div>
      </div>
    </Form>
        <p className="text-center text-body"><small>Already signed up? <a href="/app/#/log_in" className="text-reset"><u>Login here</u></a></small></p>
    </>
  );
}

export default withRouter(VerifySignUp);
