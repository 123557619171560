import { action, thunk } from 'easy-peasy';
import { getKeys, createKey } from '../services/APIKeys';


const APIKeysModel = {
  keys: [],
  showKeys: action((state, new_keys) => {
    state.keys = new_keys;
  }),
  getKeys: thunk(async (actions, payload) => {
    const new_keys = await getKeys();
    actions.showKeys(new_keys);
  }),
  createKey: thunk(async (actions, payload) => {
    await createKey();
    await actions.getKeys();
  })
};


export default APIKeysModel;
