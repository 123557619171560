import React, {useState} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Form';
import { useStoreActions } from "easy-peasy";


export default function Twilio() {
  const createTwilio = useStoreActions(actions => actions.integrations.createTwilio);
  const [sid, setSID] = useState('');
  const [token, setToken] = useState('');
  const [phone, setPhone] = useState('');

  const connectTwilio = (e) => {
    e.preventDefault();
    createTwilio({sid: sid, token: token, phone: phone});
  };

  return (
    <div className="p-3 bg-white rounded shadow-green">
      <Form onSubmit={connectTwilio}>
        <Row>
          <Col>
            <Form.Label>Account SID:</Form.Label>
            <Form.Control as="input" size={40} value={sid} onChange={(e) => setSID(e.target.value)}/>
          </Col>
          <Col>
            <Form.Label>Auth Token:</Form.Label>
            <Form.Control as="input" size={32} value={token} onChange={(e) => setToken(e.target.value)}/>
          </Col>
          <Col>
            <Form.Label>"From" Phone Number:</Form.Label>
              <Form.Control as="input" size={12} value={phone} onChange={(e) => setPhone(e.target.value)}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" onClick={connectTwilio} className="my-3">Save</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
