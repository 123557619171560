import React, {useEffect} from 'react';

function Origin (props) {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <>
      <div id="page-banner" className="origin container-fluid py-5 position-relative">
          <div className="container">
          <div className="row py-5">
              <div className="col text-white text-center py-5">
                  <h1 className="mt-5 text-uppercase">our roots</h1>
                  <h2 className="fs-3 mt-4">We've spent the last 20 years helping athletic Coaches and administrators in every sport, at all levels, make better use of technology and the Internet to recruit, fundraise, and manage their teams.</h2>
              </div>
          </div>
          </div>
      <div className="overlay"></div>
      </div>


      <section className="panel bg-white">
      <div className="container py-5 h-100">

          <div className="row d-flex align-items-center h-100">
              <div className="col-md-6">
                  <h2><a href="https://www.scoutware.com/" target="_blank" rel="noreferrer" className="text-danger text-uppercase">Scoutware</a><sup>&trade;</sup> Recruiting Communications System</h2>
                  <ul>
                      <li>1st online recruiting database in college athletics.</li>
                      <li>1st email recruiting service in college athletics.</li>
                      <li>Celebrating 20 years of service!</li>
                  </ul>
              </div>
              <div className="col-md-6">
                 <img src="images/phones-2015.png" className="d-block mx-auto img-fluid" alt="Scoutware" />
              </div>
          </div>

      </div>
      </section>


      <section className="panel bg-light">
      <div className="container py-5 h-100">
          <div className="row d-flex align-items-center h-100">
              <div className="col-md-6 order-md-1">
                  <h2>Our Invention</h2>
                  <p>As fast as technology is evolving, college Coaches must always be a step ahead in order to communicate effectively with the past, present, and future of the program.</p>
                  <p>We developed HTMAIL when recruits stopped reliably reading email.</p>
              </div>
              <div className="col-md-6 order-md-0">
                  <img src="images/HTMAIL_IfYouCanSeeItInABrowser_03.jpg" className="w-100 img-fluid" alt="Scoutware - Our Invention" />
              </div>
          </div>
      </div>
      </section>
      {/*<section className="panel bg-white border-top border-5">
        <div className="container py-5 h-100">
            <div className="row d-flex align-items-center h-100">
                <div className="col-md-6">
                    <h2>Trust-based messaging</h2>
                    <p>An HTMAIL message is sent out only as the link to it is clicked on.</p>
                    <p>Unlike conventional forms of messaging, HTMAIL messages are requested first, sent after.  Turns out this distinctive approach offers up a number of significant advantages, but only if people are clicking on your links.</p>
                    <p>To that end, our new patent-pending HTMAIL technology features a proof-not-spoof method of getting your messages opened and responded to.  And not just the first time, but again and again and again...</p>
                    <h3 className="text-success mt-4">SEE FOR YOURSELF</h3>
                    <p>Text your name and "TRUST" to to <a href="sms:1-312-548-8808" className="fw-bold">1-312-548-8808</a> and we’ll reply with a link to example of how HTMAIL links are different from bogus, often malicious links commonly found inside phishing texts purposefully intended to mislead and deceive (and irritate and enrage).</p>
                </div>
                <div className="col-md-6">
                    <img src="images/HTMAIL_TrustBasedMessaging_01.jpg" className="img-fluid" alt="Trust-based messaging" />
                </div>
            </div>
        </div>
      </section>*/}

      {/*<section className="panel bg-white">
          <div className="container py-5 h-100">
              <div className="row d-flex align-items-center h-100">
                  <div className="col-md-6">
                      <h2>360 Communications</h2>
                      <p>As fast as technology is evolving, Coaches must stay a step ahead to communicate effectively with the past, present, and future of the program. </p>
                      <p>HTMAIL messages are distributed as simple branded short links... often nested in texts or posted to social media...  can be shared however links can be. </p>
                  </div>
                  <div className="col-md-6">
                      <img src="images/HTMAIL_360Communications_02.jpg" className="img-fluid" alt="360 Communications" />
                  </div>
              </div>
          </div>
      </section>*/}



      </>
    );
}

export default Origin;
