import {doGet} from './Base';


const getUsers = async () => {
  const url = process.env.REACT_APP_API_URL + '/account/users';
  return await doGet(url, true);
};


export default getUsers;
