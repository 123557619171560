import React from 'react';
import { useStoreState } from "easy-peasy";

import {format_date} from '../Utils';


export default function Link(props) {
  const current_domain = useStoreState(state => state.current_domain);

  return (
  <tr>
      <td>{props.link.contact_id}</td>
      <td>{props.link.url}</td>
      <td>{props.link.status} {format_date(props.link.status_date)}</td>
      <td><a href={process.env.REACT_APP_BACKEND_URL + "/preview/link/" + current_domain.domain + '/' + props.link.short_id} target="_blank" rel="noreferrer">Preview</a></td>
  </tr>
  );
}
