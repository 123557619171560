import { doGet } from './Base';


const getSubscriptions = async () => {
  // TODO: pass account id?
  return await doGet('/subscriptions', {}, false);
};


const getSubscriptionTypes = async () => {
  return await doGet('/subscription/types', {}, false);
};


export {getSubscriptions, getSubscriptionTypes};
export default getSubscriptionTypes;
