import React, {useEffect} from 'react';
import { useStoreActions } from "easy-peasy";


export default function Contact(props) {
  const selectContact = useStoreActions(actions => actions.contact_list.selectContact);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <tr onClick={() => selectContact(props.contact)}>
        <td key="id">{props.contact.contact_id}</td>
        <td key="phone">{props.contact.contact_data.phone}</td>
        {props.fields && props.fields.map(field => (
          <td key={field}>{props.contact.contact_data[field]}</td>
        ))}
        <td key="edit"><i className="far fa-edit " onClick={() => selectContact(props.contact)}></i></td>
    </tr>
  );
}
