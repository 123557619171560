import React, {useState} from 'react';
import Table from 'react-bootstrap/Table';
import { useStoreActions, useStoreState } from "easy-peasy";


export default function ContactFields() {
  const [new_field, setNewField] = useState('');
  const contact_fields = useStoreState(state => state.contact_fields.fields);
  const createField = useStoreActions(actions => actions.contact_fields.createField);

  const saveContactField = (e) => {
    e.preventDefault();

    if (!new_field || new_field.length < 3 || new_field.includes(' ')) {
      window.alert("Fields must be at least 3 characters long and may not contain spaces");
      return;
    }
    createField(new_field);
    setNewField('');
  }

  return (
    <div>
      <h3>Contact Fields</h3>
      <p>Fields must be at least 3 characters long and may only contain alphanumeric characters, "-", and "_".</p>
      <Table striped responsive>
        <tbody>
        {contact_fields.map(field => {
          return <tr key={field}><td>{field}</td></tr>;
        })}
        </tbody>
      </Table>
      <form onSubmit={saveContactField}>
          <strong>New Field:</strong> <br/><input onChange={(e) => setNewField(e.target.value)} value={new_field} type="text"/><button className="btn btn-primary" type="submit" variant="success">Create</button>
      </form>
    </div>
  )
}
