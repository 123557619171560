import { action, thunk } from 'easy-peasy';
import { getContactFields, createContactField } from '../services/Contacts';

const ContactFieldsModel = {
  fields: [],
  showFields: action((state, new_fields) => {
    state.fields = new_fields.field_names;
  }),
  getFields: thunk(async (actions) => {
    let fields = await getContactFields();
    actions.showFields(fields);
  }),
  createField: thunk(async (actions, field) => {
    await createContactField(field);
    await actions.getFields();
  })
};

export default ContactFieldsModel;
