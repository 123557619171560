import React, {useState, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { useStoreActions, useStoreState } from "easy-peasy";


export default function SendModal(props) {
  const [message_text, setMessageText] = useState('');
  const [selected_links, setSelectedLinks] = useState([]);

  const links = useStoreState(state => state.message_links.links);
  const getLinks = useStoreActions(actions => actions.message_links.getLinks);
  const sendMessage = useStoreActions(actions => actions.messages.sendMessage);

  useEffect(() => {
    getLinks(props.message)
  }, [props.message, getLinks]);

  const selectLink = (link) => {
    let i = selected_links.findIndex(elem => elem.link_id === link.link_id);
    if (i > -1) {
        setSelectedLinks(selected_links.filter(e => e.link_id !== link.link_id));
    } else {
      let selected = [...selected_links];
      selected.push(link);
      setSelectedLinks(selected);
    }
  };

  const isLinkSelected = (link) => {
    return selected_links.findIndex(elem => elem.link_id === link.link_id) > -1;
  };

  const messageChange = (e) => {
    setMessageText(e.target.value);
  }

  const send_message = (e) => {
    e.preventDefault();
    if (selected_links.length === 0) {
      alert("You must select at least one link");
      return;
    }
    sendMessage({
      message_text: message_text,
      links: selected_links
    }).then(onCancel());
  };

  const clearForm = () => {
    setSelectedLinks([]);
    setMessageText('');
  };

  const onCancel = () => {
    clearForm();
    props.cancelSend();
  };
  
  return (
    <Modal show={props.show} onHide={onCancel} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Send links for "{props.title}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={send_message}>
          <Form.Group md="4">
            <Form.Label>Select links:</Form.Label>
            <ListGroup>
            {links.length && links.map((link) => {
                return <ListGroup.Item key={link.link_id} onClick={e => {e.preventDefault(); selectLink(link)}} active={isLinkSelected(link)} action>{link.contact_id} - {link.url}</ListGroup.Item>
            })}
            </ListGroup>
          </Form.Group>
          <Form.Group md="4">
            <Form.Label>Select method:</Form.Label>
            <Form.Control as="select">
              <option>SMS (Twilio)</option>
            </Form.Control>
          </Form.Group>
          <Form.Group md="4">
            <Form.Label>Enter your message, use "LINK" where the message link should go:</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={messageChange} value={message_text} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button variant="primary" onClick={send_message}>Send</Button>
      </Modal.Footer>
    </Modal>
  );
}
