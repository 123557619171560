import React, {useState} from 'react';

import { useStoreState } from "easy-peasy";
import Alert from 'react-bootstrap/Alert';

import Table from 'react-bootstrap/Table';
import Contact from './Contact';
import ContactEdit from './ContactEdit';


export default function ContactList() {
  const contacts = useStoreState(state => state.contact_list.contacts);
  const contact_fields = useStoreState(state => state.contact_fields.fields);
  const selected_contact = useStoreState(state => state.contact_list.selected_contact);
  const [show, setShow] = useState(true);

  return (
  <>
    <Alert show={show} variant="warning" onClose={() => setShow(false)} dismissible>
      <p><strong>To send your first HTMAIL</strong>, you need to create at least one contact.</p>
    </Alert>
    <div>
      <h3>Contacts</h3>
      <Table striped bordered hover responsive className="bg-white">
        <thead className="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">phone</th>
              {contact_fields.length > 0 ? contact_fields.map(field => (
              <th key={field} scope="col">{field}</th>
              )) : ''}
              <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {contacts.length >0 ?
          contacts.map(contact => (
            <Contact fields={contact_fields} contact={contact} key={contact.contact_id}/>
          ))
          : ''
        }
        </tbody>
      </Table>
      <ContactEdit contact={selected_contact}/>
    </div>
  </>
  );
}
