import React from 'react';

import Button from 'react-bootstrap/Button';

import { useStoreActions } from "easy-peasy";


export default function Domain(props) {
  const checkDomain = useStoreActions(actions => actions.domains.checkDomain);

  const checkThisDomain = async (domain) => {
    await checkDomain(domain);
  }
  return (
    <tr>
    <td>{props.domain.domain}</td><td>{props.domain.status}</td>
    <td className="text-center"><Button onClick={() => checkThisDomain(props.domain)} variant="primary">Check</Button></td>
    </tr>
  );
}
