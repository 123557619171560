import React, {useState, useEffect} from 'react';

import { withRouter, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions, useStoreState } from "easy-peasy";

function SignUp(props) {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [accepted_terms, setAcceptedTerms] = useState(false);
  const [is_signed_up, setIsSignedUp] = useState(false);
  const signUp = useStoreActions(actions => actions.signUp);
  const is_logged_in = useStoreState(state => state.is_logged_in);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (is_logged_in && !props.footer) {
      props.history.push("/");
    }
  }, [is_logged_in, props.history, props.footer]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!accepted_terms) {
      alert("You must accept the Terms and Conditions in order to sign up");
      return;
    }
    signUp({email: email, phone: phone, first_name: first_name, last_name: last_name})
    .then(response => {
      if (response.success) {
        setIsSignedUp(true)
        if (props.footer) {
          window.alert("Success!  You will be texted a link to complete your sign-up");
        }
        props.history.push("/log_in");
      } else if (response.errors) {
        window.alert(response.errors);
      }
    });
  };

  if (props.footer) {
    return (
                <>
                    <div className="col-md-6 pe-md-5">
                        <h4 className="mb-3">Sign Up For Free</h4>
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <Form.Group className="col-sm-6 mb-3">
                                  <Form.Label className="form-label mb-0">First Name</Form.Label>
                                  <Form.Control type="input" className="form-control" onChange={(e) => setFirstName(e.target.value)} value={first_name}/>
                                </Form.Group>
                                <Form.Group className="col-sm-6 mb-3">
                                  <Form.Label className="form-label mb-0">Last Name</Form.Label>
                                  <Form.Control type="input" className="form-control" onChange={(e) => setLastName(e.target.value)} value={last_name}/>
                                </Form.Group>
                                <Form.Group className="col-sm-6 mb-3">
                                    <Form.Label className="form-label mb-0">Mobile Phone*</Form.Label>
                                    <Form.Control className="form-control" type="input" onChange={(e) => setPhone(e.target.value)} value={phone} aria-describedby="emailHelp"/>
                                </Form.Group>
                                <Form.Group className="col-sm-6 mb-3">
                                  <Form.Label className="form-label mb-0">Email</Form.Label>
                                  <Form.Control type="input" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email}/>
                                </Form.Group>
                                <Form.Group className="col-sm-12 mb-3">
                                  <div className="row">
                                    <div className="col text-center px-0">
                                      <Form.Check onChange={(e) => setAcceptedTerms(e.target.value)} value={accepted_terms}/>
                                    </div>
                                    <div className="col-11 ps-0">
                                      <Form.Label>I agree to the <Link to="/terms" target="_blank" rel="noreferrer" className="text-success">Terms of Use</Link></Form.Label>
                                    </div>
                                  </div>
                                </Form.Group>

                                <Form.Group className="col-sm-12 mb-3">
                                  <button type="submit" className="btn btn-success btn-lg">Go</button>
                                </Form.Group>
                            </div>
                            <div id="emailHelp" className="form-text my-3">To complete your registration, an HTMAIL link will be texted to you.<br/>
                                <br/>*By filling out this form you agree to receive text messages. Standard data rates may apply. We will never sell your information to third parties.</div>
                        </Form>
                    </div>
                    </>
    );
  }

  return (
    <>
    {is_signed_up && (
    <p>You have successfully signed up.  You will receive a text with a link to finish creating your account.</p>
    )}
    {!is_signed_up && (
      <>

      {/*<div className="container pt-5">
        <div className="row">
            <div className="col text-center">
                <h1 className="text-uppercase">Pricing</h1>
            </div>
        </div>
      </div>

      <section>
        <div className="container py-5">
        <div className="row d-flex">
            <div className="col-md-5 mx-auto shadow mb-4 mb-md-0 p-3 p-md-4 rounded-3 bg-dark text-white border border-light border-2">
                <h2 className="mb-4">Five Dollar Alphas.</h2>
                <ul className="checklist p-0">
                  <li className="h5">Your own private developer account</li>
                  <li className="h5">Dedicated personal domain name.</li>
                  <li className="h5">Formidable new communications superpowers.</li>
                </ul>
                <p className="h5 text-success text-uppercase mb-0">All just $5 per month.</p>
                <p className="text-muted fst-italic"><small>Alpha pricing is only available for a limited time. Setup fee may apply. </small></p>
            </div>
            <div className="col-md-5 mx-auto shadow mb-4 mb-md-0 p-3 p-md-4 rounded-3 bg-dark text-white border border-light border-2">
                <h2 className="mb-4">Try It Out.</h2>
                <ul className="checklist p-0">
                <li className="h5">Full API access.</li>
                <li className="h5">30 days free. </li>
                <li className="h5">No credit card required.</li>
                </ul>
            </div>
        </div>
        </div>
      </section>*/}
      <div className="row text-center pt-5">
        <div className="col-12">
          {/*<h1 className="font-weight-bold">See For Yourself</h1>*/}
          <h2>Welcome!</h2>
          <h3 className="mb-3">Let's create your account.</h3>
        </div>
      </div>
    <Form id="signup" className="row mx-3 d-flex justify-content-center" onSubmit={handleSubmit}>
      <div className="col-12 col-md-6 mx-auto bg-white shadow-green rounded py-3 m-3 m-sm-0">
        <div className="col-12">
          <img src="images/HTMAIL_airplaneIcon.png" className="icon-send d-block mx-auto my-3" alt="Sign Up" />
        </div>
        <Form.Group className="col-12">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="input" onChange={(e) => setFirstName(e.target.value)} value={first_name}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="input" onChange={(e) => setLastName(e.target.value)} value={last_name}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Mobile Phone</Form.Label>
          <Form.Control type="input" onChange={(e) => setPhone(e.target.value)} value={phone}/>
        </Form.Group>
        <Form.Group className="col-12">
          <Form.Label>Email</Form.Label>
          <Form.Control type="input" onChange={(e) => setEmail(e.target.value)} value={email}/>
        </Form.Group>
        <Form.Group className="col-12 mx-auto my-3">
          <div className="row">
            <div className="col text-center px-0">
              <Form.Check onChange={(e) => setAcceptedTerms(e.target.value)} value={accepted_terms}/>
            </div>
            <div className="col-11 ps-0">
              <Form.Label>I agree to the <Link to="/terms" target="_blank" rel="noreferrer">Terms of Use</Link></Form.Label>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="col-12 mx-auto my-3">
        </Form.Group>

        <div className="col-12">
          <Button type="submit" variant="success btn-lg w-100 text-uppercase mt-4">Start My Free Trial</Button>
        </div>
      </div>
    </Form>
    <div className="row">
    <div className="col-md-6 mx-auto">
      <p className="text-center text-body mt-3">
          <small>To complete your registration, an HTMAIL link will be texted to you.<br/>
*By filling out this form you agree to receive text messages. Standard data rates may apply. We will never sell your information to third parties.
.<br/>
          Already signed up? <Link to="/log_in" className="text-reset"><u>Login here</u></Link></small>
      </p>
</div>
</div>
      </>
    )}
    </>
  );
}

export default withRouter(SignUp);
