import React, {useState, useEffect} from 'react';

import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions, useStoreState } from "easy-peasy";


function LogIn2FA(props) {
  const [code, setCode] = useState('');
  const [trust_browser, setTrustBrowser] = useState(false);
  const logIn2FA = useStoreActions(actions => actions.logIn2FA);
  const is_logged_in = useStoreState(state => state.is_logged_in);

  useEffect(() => {
    if (is_logged_in) {
      props.history.push("/");
    }
  }, [is_logged_in, props.history]);

  const handleSubmit = (e) => {
    console.log("IN handle submit");
    e.preventDefault();
    logIn2FA({code: code, trust_browser:trust_browser})
    .then(response => {
      if (response.success) {
        props.history.push("/");
      } else if (response.errors) {
        window.alert(response.errors);
      }
    });
  };

  return (
    <>

      <div className="row pt-5 mt-5">
        <div className="col-md-8 mx-auto">
        <p>This browser has not been verified in the past 30 days. A verification code has been sent to your phone.
            To continue logging in, enter the code you received via text message below.</p>

    <Form id="2fa" className="row bg-white shadow-green rounded py-3 m-3 m-sm-0" onSubmit={handleSubmit}>
      <Form.Group className="col-12 mx-auto">
        <Form.Label>Enter Code:</Form.Label>
        <Form.Control type="input" onChange={(e) => setCode(e.target.value)} value={code}/>
      </Form.Group>
      <Form.Group className="col-12 mx-auto my-3">
        <Form.Check onChange={(e) => setTrustBrowser(!trust_browser)} className="float-start me-2"/>
        <Form.Label>Trust this browser for 30 days:</Form.Label>
      </Form.Group>
      <div className="col-md-6 mx-auto">
        <Button type="submit" variant="success btn-lg w-100 text-uppercase">Log In</Button>
      </div>
    </Form>
    </div>
  </div>

    </>
  );
}

export default withRouter(LogIn2FA);
