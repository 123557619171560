import {doPost, doGet, doPut} from './Base';


const getMessages = async (domain) => {
  if (!domain) {
      return [];
  }
  const url = process.env.REACT_APP_API_URL + '/messages/' + domain;
  return await doGet(url, {}, true);
};


const saveMessage = async (message) => {
  let url = process.env.REACT_APP_API_URL + "/message/";
  if (message.message_id) {
    url += message.message_id;
    return await doPut(url, message, true);
  }
  return await doPost(url, message, true);
};


const sendMessage = async (message_text, links) => {
  const body = {
    message: message_text,
    link_ids: links.map(link => link.link_id)
  }
  return await doPost('/send_links', body);
}


export {getMessages, saveMessage, sendMessage};
export default getMessages;
