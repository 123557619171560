import React, {useState, useEffect} from 'react';

import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions, useStoreState } from "easy-peasy";

function ForgotPassword(props) {
  const [username, setUsername] = useState('');
  const is_logged_in = useStoreState(state => state.is_logged_in);
  const resetPassword = useStoreActions(actions => actions.password.resetPassword);

  useEffect(() => {
    if (is_logged_in) {
      props.history.push("/");
    }
  }, [is_logged_in, props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword({username: username})
    .then(response => {
      if (response.success) {
        window.alert('Success. We will send you a password reset link soon.');
        props.history.push("/");
      } else if (response.errors) {
        window.alert(response.errors);
      }
    });
  };

  return (
    <>

      <div className="row pt-5 mt-5">
        <div className="col-12">
          <h1 className="text-center">Forgot Password</h1>
        </div>
      </div>
    <Form id="signup" className="row d-flex justify-content-center mx-3" onSubmit={handleSubmit}>
      <div className="col-12 col-md-6 mx-auto bg-white shadow-green rounded py-3 m-3 m-sm-0">
      <p className="text-center">Enter your phone number below, and we will send you a link you can use to reset your password.</p>
        <Form.Group className="col-12">
          <Form.Label>Phone number</Form.Label>
          <Form.Control type="input" onChange={(e) => setUsername(e.target.value)} value={username}/>
        </Form.Group>
        <div className="col-12">
          <Button type="submit" variant="success btn-lg w-100 text-uppercase mt-4">Submit</Button>
        </div>
      </div>
    </Form>

    </>
  );
}

export default withRouter(ForgotPassword);
