import React, {useState, useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import Message from './Message';
import MessageEdit from './MessageEdit';
import SendModal from './SendModal';
import LinksList from './LinksList';
import MessageReport from './MessageReport';
import { useStoreActions, useStoreState } from "easy-peasy";


export default function MessageList () {
  const [sendFor, setSendFor] = useState('');
  const [showLinksFor, setShowLinksFor] = useState({});
  const [showReportsFor, setShowReportsFor] = useState({});
  const selected_message = useStoreState(state => state.messages.selected_message);
  const message_list = useStoreState(state => state.messages);
  const domain = useStoreState(state => state.current_domain);
  const get_messages = useStoreActions(actions => actions.messages.getMessages);

  useEffect(() => {
      get_messages(domain.domain);
  }, [domain, get_messages]);

  return (
    <div className="container px-0">
      {Object.keys(showLinksFor).length === 0 && Object.keys(showReportsFor).length === 0 &&
        <div className="row">
        <div className="col-12">
        <h3>Messages</h3>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>&nbsp;</th><th>Name</th><th>Last Updated</th><th>&nbsp;</th><th>&nbsp;</th><th>&nbsp;</th><th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
          {message_list.messages.map(message => (
            <Message
              showLinksFor={setShowLinksFor}
              message={message}
              showSendFor={setSendFor}
              showReportsFor={setShowReportsFor}
              key={message.message_id}
            />
          ))}
          </tbody>
        </Table>
        <MessageEdit message={selected_message}/>
      </div>
      </div>
      }
        <SendModal show={sendFor ? true : false} message={sendFor} cancelSend={() => setSendFor('')} title={sendFor && sendFor.title}/>
      {showLinksFor.message_id &&
      <div className="row">
        <div className="col">
          <LinksList hideLinks={() => setShowLinksFor({})} message={showLinksFor}/>
        </div>
      </div>
        }
      {showReportsFor.message_id &&
      <div className="row">
        <div className="col">
        <MessageReport hideReports={() => setShowReportsFor({})} message={showReportsFor}/>
        </div>
      </div>
        }
    </div>
  );
}
