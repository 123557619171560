import React, {useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import { useStoreActions, useStoreState } from "easy-peasy";

import {format_date} from "../Utils";


export default function MessageReport(props) {
  const views = useStoreState(state => state.reports.message_views);
  const get_views = useStoreActions(actions => actions.reports.getMessageViews);

  useEffect(() => {
    if (props.message) {
      get_views(props.message);
    }
  }, [props.message, get_views]);

  const showMessages = (e) => {
    e.preventDefault();
    props.hideReports();
  }

  return (
    <div className="container pt-5">
      <div className="row">
        <div className="col-12">
          <h3>Report for "{props.message && props.message.title}"</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <a href="/messages" onClick={showMessages}>Back to Messages</a>
        </div>
      </div>
        {views.length ? (
          <div className="row">
            <div className="col-12">
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Link URL</th><th>Date Viewed</th><th>IP</th><th>Browser</th>
                </tr>
              </thead>
              <tbody>
                {views.length && views.map(view => (
                <tr key={view.url}>
                  <td>{view.url}</td><td>{format_date(view.view_time)}</td><td>{view.ip_address}</td><td>{view.browser}</td>
                </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        ) : <p>No views yet.</p>}
    </div>
  );
}
