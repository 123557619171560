import React, {useState, useEffect} from 'react';
import { useStoreActions, useStoreState } from "easy-peasy";
import Table from 'react-bootstrap/Table';


export default function ContactEdit(props) {
  const [contact_id, setID] = useState(Object.keys(props.contact).length ? props.contact.contact_id : '');
  const [contact_data, setData] = useState(Object.keys(props.contact).length ? props.contact.contact_data : {});
  const [showForm, setShowForm] = useState(Object.keys(props.contact).length ? true : false);
  const contact_fields = useStoreState(state => state.contact_fields.fields);
  const saveContact = useStoreActions(actions => actions.contact_list.saveContact);
  const selectContact = useStoreActions(actions => actions.contact_list.selectContact);

  useEffect(() => {
    if (Object.keys(props.contact).length) {
      setShowForm(true);
      setID(props.contact.contact_id);
      setData({...props.contact.contact_data});
    }
  }, [props.contact]);

  const onFieldChange = (e) => {
    let new_contact_data = {...contact_data};
    new_contact_data[e.target.name] = e.target.value;
    setData(new_contact_data);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!contact_data.phone) {
      alert("Phone number is required");
      return;
    } else if (!contact_id) {
      alert("Contact ID is required");
      return;
    }
    let contact = {
      contact_id: contact_id,
      contact_data: contact_data,
    };
    saveContact(contact);
    setShowForm(false);
    clearForm();
  };

  const newContact = () => {
    setShowForm(true);
    clearForm();
  };

  const clearForm = () => {
    selectContact({});
    setData({});
    setID('');
  };

  return (
    <div id="contact_edit">
      <div>
        <button onClick={newContact} className="btn btn-primary">New Contact</button>
      </div>
      <br/>
    { showForm && (
      <div className="bg-white rounded p-3 shadow-green">
        <h3>{props.contact ? "Edit Contact" : "New Contact"}</h3>
        <form onSubmit={onSubmit}>
          <Table responsive>
            <tbody>
              <tr>
                <td>Contact ID: </td>
                <td><input type="text" name="contact_id" value={contact_id} onChange={(e) => setID(e.target.value)} readOnly={props.contact.contact_id ? true:false}/></td>
              </tr>
              <tr>
                <td>Phone: </td>
                <td><input type="text" name="phone" value={contact_data['phone'] || ''} onChange={onFieldChange} /></td>
              </tr>
              {contact_fields.length && contact_fields.map(field => (
                <tr key={field}>
                  <td>{field}: </td>
                    <td><input type="text" name={field} value={contact_data[field] || ''} onChange={onFieldChange}/></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <button type="submit" className="btn btn-primary">Save</button>
        </form>
      </div>
      )}
    </div>
  );
}
