import React from 'react';
import Table from 'react-bootstrap/Table';
import { useStoreState } from "easy-peasy";


export default function Users() {
  const users = useStoreState(state => state.users.users);

  return (
    <div>
      <h3>Users</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Phone</th>
          </tr>
        </thead>
        <tbody>
        {users.length && 
          users.map(user => (
            <tr>
              <td>{user.first_name} {user.last_name}</td>
              <td>{user.phone}</td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </div>
  );
}
