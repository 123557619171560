import {doPost} from './Base';


const logIn = async (username, password) => {
  return await doPost('/login', {username: username, password: password});
};


const logIn2FA = async (code, trust_browser) => {
  return await doPost('/login/2fa', {code: code, trust_browser: trust_browser});
};


const signUp = async (email, phone, first_name, last_name) => {
  const body = {
    email: email,
    phone: phone,
    first_name: first_name,
    last_name: last_name,
  };
  return await doPost('/signup', body);
};


const verify = async (code, password) => {
    return await doPost('/verify_account', {verify_code: code, password: password});
};


export {logIn, signUp, logIn2FA, verify};
export default logIn;
