import React from 'react';
import { useStoreState, useStoreActions } from "easy-peasy";


export default function APIKeys() {
  const api_keys = useStoreState(state => state.api_keys.keys);
  const createKey = useStoreActions(actions => actions.api_keys.createKey);

  const createAPIKey = (e) => {
    e.preventDefault();
    createKey();
  };

  return (
    <div>
      <ul>
        {api_keys.length && api_keys.map((key) => (
          <li key={key}>{key}</li>
        ))}
      </ul>
      <p>
        <a href="/create_api_key" onClick={createAPIKey} className="btn btn-primary">Create new key</a>
      </p>
    </div>
  );
}
