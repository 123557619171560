import React, {useEffect} from 'react';

import { withRouter } from 'react-router-dom';
import {useStoreState } from "easy-peasy";

function LogOut(props) {
  const is_logged_in = useStoreState(state => state.is_logged_in);

  useEffect(() => {
    if (is_logged_in) {
      window.location.href=process.env.REACT_APP_BACKEND_URL + '/logout';
    }
  }, [is_logged_in, props.history]);

  return (
    <>
    </>
  );
}

export default withRouter(LogOut);
