import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import Cookies from 'js-cookie';

function Subscription () {
  const [message, setMessage] = useState('');
  // const [paymentIntent, setPaymentIntent] = useState('');
  const active_subscription = useStoreState(state => state.subscriptions.active_subscription);
  const get_subscriptions = useStoreActions(actions => actions.subscriptions.getSubscriptions);
  // const subscription_types = useStoreState(state => state.subscriptions.subscription_types);
  const get_subscription_types = useStoreActions(actions => actions.subscriptions.getSubscriptionTypes);

  const paymentIntent = null;

  // const stripe = useStripe();
  // const elements = useElements();

  useEffect(() => {
    get_subscriptions();
    get_subscription_types();
  }, [get_subscription_types, get_subscriptions]);

  /* 
  if (!stripe || !elements)  {
    // don't show the form until stripe.js loads
    return '';
  }
  */

  // FIXME: try to load subscriptions every time this page is viewed
  // FIXME: On button click to subscribe, call "/create_payment" on backend
  // FIXME: let stripe = Stripe("some_key");  // FIXME: Get key from backend?
  // let elements = stripe.elements();
  // let elements = stripe.elements();
  //  var card = elements.create("card", { style: style });
  // Stripe injects an iframe into the DOM:
  // card.mount("#card-element");
  // MORE: https://stripe.com/docs/payments/integration-builder

  const handleSubmit = async (e) => {
    e.preventDefault();
      /*
    const cardElement = elements.getElement(CardElement);
    let {error, intent} = await stripe.confirmCardPayment('client secret!', {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: Cookies.get('name'),
          user_id: Cookies.get('user_id'),
          account_id: Cookies.get('account_id')
        }
      }
    });
    setPaymentIntent(intent);
    if (error) {
      setMessage(error.message);
    }
      */
  };

  if (paymentIntent && paymentIntent.status === 'succeeded') {
      setMessage('Success');
  }

  return (
    <div className="container py-5">
      <div className="row pt-5 mt-5">
        <div className="col-12">
        <h3>Subscription</h3>
        <div id="payment-modal"></div>
        {active_subscription && (
          <div>
            <p>{active_subscription.name} - {active_subscription.remaining_notifications} notifications remaining</p>
          </div>
        )}
        {!active_subscription && (
        <div>
          <p>No active subscriptions</p>
           {false && (
          <form onSubmit={handleSubmit}>
            <button>Subscribe for only $5/month.</button>
            <div>{message}</div>
          </form>
           )}
        </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default withRouter(Subscription);
