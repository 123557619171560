import React, {useState, useEffect} from 'react';
import { useStoreActions, useStoreState } from "easy-peasy";


export default function MessageEdit(props) {
  const [showForm, setShowForm] = useState(Object.keys(props.message).length ? true : false);
  const [message, setMessage] = useState(Object.keys(props.message).length ? props.message : {});
  const selectMessage = useStoreActions(actions => actions.messages.selectMessage);
  const saveMessage = useStoreActions(actions => actions.messages.saveMessage);
  const domain = useStoreState(state => state.current_domain);

  useEffect(() => {
    if (Object.keys(props.message).length) {
      setShowForm(true);
      setMessage(props.message);
    }
  }, [props.message]);

  const onFieldChange = (e) => {
    let new_message = {...message};
    new_message[e.target.name] = e.target.value;
    setMessage(new_message);
  };

  const newMessage = () => {
    setShowForm(true);
    clearForm();
  };

  const clearForm = () => {
    selectMessage({});
    setMessage({title: '', template: '', message_id: ''});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!message.title) {
      alert("You must provide a title for the message");
      return;
    }
    if (!message.template) {
      alert("You must provide content for the message");
      return;
    }
    let new_message = {...message};
    new_message.domain = domain.domain;
    saveMessage(new_message);
    setShowForm(false);
    clearForm();
    window.scrollTo(0, 0);
  }

  return (
    <div className="container px-0" id="message_edit">
      <div className="row">
        <div className="col">
          <button onClick={newMessage} className="btn btn-primary">New Message</button>
        </div>
      </div>
      <br/>
      { showForm && (
        <div className="row">
          <div className="col-12">
            <h3>{message.title || 'New Message'}</h3>
          </div>
          <div className="row">
            <div className="col-12">
                <p>Edit your message below.  To custom your message, add placeholders for your Contact Fields by putting them in double curly braces.  For example:<br/>
                    <pre>Hello, {"{{FirstName}}"}</pre>
                    Be sure to spell the Contact Field correctly, and do not add spaces
                </p>
            </div>
          </div>
          <div className="col-12">
          <form id="newMessage" onSubmit={onSubmit}>
            <label >Title: </label>
            <input type="hidden" name="message_id" value={message.message_id || ''}/><br/>
            <input type="hidden" name="domain" value={domain.domain}/><br/>
            <input type="text" name="title" value={message.title} onChange={onFieldChange}/><br/>
            <textarea rows="30" cols="100" name="template" value={message.template} onChange={onFieldChange}/><br/>
            <button type="submit" className="btn btn-primary">Save</button>
          </form>
          </div>
        </div>
      )}
    </div>
  );
}
