import {doPost, doGet} from './Base';

const getKeys = async () => {
  return doGet('/keys');
};

const createKey = async () => {
  return doPost('/create_key', {});
};


export default getKeys;
export {getKeys, createKey};
