import React, {useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useStoreState } from "easy-peasy";
import { HashLink } from 'react-router-hash-link';

function Home (props) {
  const is_logged_in = useStoreState(state => state.is_logged_in);
  const contacts = useStoreState(state => state.contact_list.contacts);

  useEffect(() => {
    window.scrollTo(0, 0);
    const script = document.createElement('script');
    script.src = "js/jquery.animateTyping.js";
    script.async = true;
    document.body.appendChild(script);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
    {!is_logged_in &&
      <>

      <div className="bounce"><HashLink to="#works"></HashLink></div>
      <div className="hero-video-wrap" dangerouslySetInnerHTML={{ __html: `
        <video id="hero" playsinline loop autoPlay muted autobuffer poster="./images/htmail-vidThumb.jpg">
        <source src="./images/htmail-vidbg.mp4" type="video/mp4" />
        <source src="./images/htmail-vidbg.webm" type="video/webm" />
        <source src="./images/htmail-vidbg.ogv" type="video/ogg" />
        <source src="./images/htmail-vidbg.m4v" type="video/m4v" />
        <img src="./images/htmail-vidThumb.jpg" alt=""/>
        Your browser does not support the video tag. I suggest you upgrade your browser.
        </video>
      ` }}>
      </div>
      <div className="overlay"></div>
      <section className="py-5 text-center vidOverlay">
      <div className="container h-100">
          <div className="row d-flex align-content-center h-100 text-white">
              <div className="intro col-md-8 mx-auto position-relative">
                  <h1 className="mb-5">Introducing HTMAIL</h1>
                  <p className="h3 mb-5">The fastest way on earth to get email in front of</p>
                        <div
                                      className="text-success h2 mb-5 animate-typing"
                                      data-animate-loop="true"
                                      data-type-speed="100"
                                      data-type-delay="200"
                                      data-remove-speed="50"
                                      data-remove-delay="2000"
                                      data-cursor-speed="200">
                                          RECRUITS|
                                          TEAMMATES|
                                          CUSTOMERS|
                                          CLIENTS|
                                          PROSPECTS|
                                          EMPLOYEES|
                                          ASSOCIATES|
                                          STAFF|
                                          SUPPORTERS|
                                          SUBSCRIBERS|
                                          SUPPLIERS|
                                          VOLUNTEERS|
                                          FRIENDS
                                  </div>
                  <p className="h3 mb-0">who know you… but don’t often check their inbox.</p>
              </div>

          </div>
      </div>
      </section>

{ /*     <section id="works" className="panel bg-white">
      <div className="container">
          <div className="row pt-5 d-flex align-items-center">
              <div className="col-md-6 order-md-1">
                  <h2>20 Years in the Making</h2>
                  <p>For twenty years, Scoutware has been helping college coaches of every sport, at every level, make more effective use of technology and the Internet to recruit student athletes.</p>
                  <p>Originally developed as a viable alternative to email when recruits stopped reading it, HTMAIL is now quite often the superior option.</p>
              </div>
              <div className="col-md-6 pe-md-5">
                  <div className="video-wrap" dangerouslySetInnerHTML={{ __html: `
                    <video playsinline loop autoPlay muted autobuffer poster="./images/iPhone_BlackClay_Front1.png" class="vidExample">
                    <source src="./images/HTMAIL_heroVid.mp4" type="video/mp4" />
                    <source src="./images/HTMAIL_heroVid.webm" type="video/webm" />
                    <source src="./images/HTMAIL_heroVid.ogv" type="video/ogg" />
                    <img src="./images/htmail-vidThumb.jpg" alt=""/>
                    Your browser does not support the video tag. I suggest you upgrade your browser.
                  </video>
                  ` }}>
                  </div>

              </div>

          </div>
      </div>
      </section> */}
      <section id="works" className="panel bg-light border-top border-5">
        <div className="container py-5 h-100">

          <div className="row pt-5 pt-md-0 d-flex align-items-center h-100">
              <div className="col-md-6 order-md-1">
                  <h2>Create Links.<br/>Message Anywhere.</h2>
                  <p>For 20 years we’ve been helping college coaches make better use of technology in recruiting.  HTMAIL was developed in response to the surge in student-athletes who for the most part stopped reading email.</p>
                  <p>Today, with just a few lines of code developers can access patented HTMAIL via API and deliver visually stunning personal messages that are easy to receive, consume, and interact with on mobile devices.</p>
              </div>
              <div className="col-md-6 text-center">
                  <img src="images/HTMAIL_CreateLinksMsgAnywhere_01.png" className="img-fluid" alt="Message Anywhere" />
              </div>
          </div>
        </div>
      </section>
      <section className="panel bg-white border-top border-5">
      <div className="container">
          <div className="row pt-5 pt-md-0 d-flex align-items-center">
              <div className="col-md-6 pt-4 pt-md-0">
                  <h2 className="pt-sm-0 mt-sm-0 pt-md-5 mt-md-5 pt-lg-0 mt-lg-0">How HTMAIL Works...</h2>
                  <p>First, post an HTML template and personalize individual HTMAIL messages with data.</p>
                  <p>Next, distribute unique, branded short-links to HTMAIL messages out to your intended recipients.</p>
                  <p>With each click, a personalized HTMAIL message springs to life inside your recipient’s web browser.</p>
                  <div className="my-4">
                    <h3 className="text-uppercase m-0 text-success lh-base">If you can see it in a browser, you can send it as <span className="text-dark">HTMAIL</span>{/*<img src="images/HTMAIL_Logo-sm.jpg" className="d-inline-block align-text-top logosm" alt="If you can see it in a browser, you can send it as HTMAIL" />*/}.</h3>
                  </div>
              </div>
              <div className="col-md-6 pe-md-5">
                <div className="video-wrap" dangerouslySetInnerHTML={{ __html: `
                  <video playsinline loop autoPlay muted autobuffer poster="./images/iPhone_BlackClay_Front1.png" class="vidExample">
                  <source src="./images/HTMAIL_heroVid.mp4" type="video/mp4" />
                  <source src="./images/HTMAIL_heroVid.webm" type="video/webm" />
                  <source src="./images/HTMAIL_heroVid.ogv" type="video/ogg" />
                  <img src="./images/htmail-vidThumb.jpg" alt=""/>
                  Your browser does not support the video tag. I suggest you upgrade your browser.
                </video>
                ` }}>
                </div>
              </div>

          </div>
      </div>
      </section>




      <section className="panel bg-light border-top border-5">
        <div className="container py-5 h-100">
            <div className="row d-flex align-items-center h-100">
                <div className="col-md-6">
                    <h2>Trust-based messaging</h2>
                    <p>An HTMAIL message is sent out only as the link to it is clicked on.</p>
                    <p>Unlike conventional forms of messaging, HTMAIL messages are requested first, sent after.  Turns out this distinctive approach offers up a number of significant advantages, but only if people are clicking on your links.</p>
                    <p>To that end, our new patented HTMAIL technology features a proof-not-spoof method of getting your messages opened and responded to.  And not just the first time, but again and again and again...</p>
                </div>
                <div className="col-md-6">
                    <img src="images/HTMAIL_TrustBasedMessaging_02.jpg" className="img-fluid" alt="Trust-based messaging" />
                </div>
            </div>
        </div>
      </section>

      <section className="panel bg-white border-top border-5">
        <div className="container pt-5 pb-0 py-md-5 h-100">
          <div className="row d-flex align-items-center h-100">
              <div className="col-md-6 order-md-1">
                  <h2 className="mt-5 mt-md-0">Outperforms email.</h2>
                  <p>To begin with, HTMAIL messages get there quicker.  Why?  Recognizable links which are prominently visible inside texts, chats and social media posts Get Immediate Attention.</p>
                  <p>And since HTMAIL bypasses email altogether, messages never see an inbox and are not subject to the same invasive spam filtering email messages must famously endure.</p>
                  <p>As a result, HTMAIL is able to offer senders full freedom of expression through content and media.*   Capabilities inherent to HTMAIL and simply not possible through standard email.</p>
              </div>
              <div className="col-md-6 text-center">
                <img src="images/HTMAIL_IfYouCanSeeItInABrowser_02.png" className="w-100 img-fluid" alt="Outperforms Email" />
              </div>
          </div>
        </div>
      </section>

      <section className="panel bg-light border-top border-5">
        <div className="container pt-5 pb-0 py-md-5 h-100">
          <div className="row d-flex align-items-center h-100">
              <div className="col-md-7">
                  <h2 className="mt-5 mt-md-0">ENGAGE ANYWHERE</h2>
                  <p>Use new Linkoln Email with your favorite social media apps to help you get more attention and generate excitement around your program, gauge interest, build trust, and move people. </p>
              </div>
              <div className="col-md-5 text-center">
                  <img src="images/HTMAIL_360Communications.jpg" className="w-100 img-fluid" alt="The Ultimate Omnichannel" />
              </div>
          </div>
        </div>
      </section>

      {/* <section className="price position-relative py-5 bg-white border-top border-5">
        <div className="container py-md-5">
          <div className="row d-flex">
              <div className="col-md-5 mx-auto shadow mb-4 mb-md-0 p-3 p-md-4 rounded-3 bg-dark text-white border border-light border-2">
                  <h2 className="mb-4">Nine Dollar Alphas.</h2>
                  <ul className="checklist p-0">
                    <li className="h5">Your own private developer account</li>
                    <li className="h5">Dedicated personal domain name.</li>
                    <li className="h5">The High Ground of Online Correspondence.</li>
                  </ul>
                  <p className="h5 text-success text-uppercase mb-0">All just $9 per month.</p>
                  <p className="text-muted fst-italic"><small>Alpha pricing is only available for a limited time. Setup fee may apply for personal domains. </small></p>
              </div>
              <div className="col-md-5 mx-auto shadow mb-4 mb-md-0 p-3 p-md-4 rounded-3 bg-dark text-white border border-light border-2">
                  <h2 className="mb-4">Check This Out...</h2>
                  <ul className="checklist p-0">
                  <li className="h5">Full API access.</li>
                  <li className="h5">30 days free. </li>
                  <li className="h5">No credit card required.</li>
                  </ul>
                  <Link to="/sign_up" className="btn btn-success btn-lg text-uppercase px-5" role="button">Get Started</Link>
              </div>
          </div>
        </div>
      </section>*/}


     </>
    }
    {is_logged_in &&
      <>
      <div id="content" className="container py-5">
      <div className="row pt-5 mt-4">
        <div className="col-md-6">
          <h2 className="h4"><i className="fas fa-tachometer-alt"></i> Dashboard</h2>
        </div>
        <div className="col-md-6 text-md-end">
        {contacts.length > 0 ? (
        <Link to="/contacts" className="btn btn-primary" role="button">Send your first HTMAIL</Link>
        ) : (
        <Link to="/messages" className="btn btn-primary" role="button">Send your first HTMAIL</Link>
        )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-dark h4 text-white">
              First Time Setup
            </div>
            <div className="card-body">

              <div className="accordion accordion-flush" id="accordionFlush">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      1. Set Up a Domain
                    </button>
                  </h2>
                  <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlush">
                    <div className="accordion-body">Before creating any HTMAIL messages, you'll want to add one of your domains so your recipients know your messages are coming from you.  To do this, click "Account" at the top and select "Domains".  After you add your domain name, you'll need to update your DNS records and add a CNAME record that points your domain to "htmail.link".  Then simply press the "Check" button next to your name to validate and start using it!
                  </div>
                </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      2. Setup Twilio
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlush">
                    <div className="accordion-body">
                        <div className="row">
                          <div className="col-12">Next, if you would like to easily send your HTMAIL messages via SMS, you need to link your Twilio account. Click the "Account" link at the top, and select "Integrations" for instructions on how to do this.
                          </div>
                          <div className="col-12">
                              <Link to="/account/integrations" className="btn btn-primary mt-3">Setup Twilio</Link>
                          </div>
                      </div>
                  </div>
                </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      3. Use API
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlush">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-12">For programmatic access to HTMAIL, we have an API available for all functionality. See the "API Docs" link under "Accounts" for the API documentation.
                        </div>
                        <div className="col-12">
                          <Link to="/account/api_keys" className="btn btn-primary mt-3">API Keys</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      </div>
      </>
    }
  </>);
}

export default withRouter(Home);
