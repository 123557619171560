import React, {Component} from 'react';


class ConnectTwilio extends Component {
  render() {
    return (
      <p>
        <a href="https://www.twilio.com/authorize/CN9fa6737ab394bd881e8e77cfe33159b4" id="twilio-connect-button" className="btn btn-primary mb-4">Connect to Twilio</a>
      </p>
    );
  }
}


export default ConnectTwilio;
