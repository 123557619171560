import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Link from './Link';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { useStoreActions, useStoreState } from "easy-peasy";


export default function LinksList(props) {
  const [selected_contacts, setSelectedContacts] = useState([]);
  const [selected_users, setSelectedUsers] = useState([]);
  const contacts = useStoreState(state => state.contact_list.contacts);
  const links = useStoreState(state => state.message_links.links);
  const users = useStoreState(state => state.users.users);
  const get_links = useStoreActions(actions => actions.message_links.getLinks);
  const create_links = useStoreActions(actions => actions.message_links.createLinks);

  useEffect(() => {
    if (props.message) {
      get_links(props.message);
    }
  }, [props.message, get_links]);

  const showMessages = (e) => {
    e.preventDefault();
    props.hideLinks();
  }

  const selectContact = (contact) => {
    let i = selected_contacts.findIndex(elem => elem.contact_id === contact.contact_id);
    if (i > -1) {
        setSelectedContacts(selected_contacts.filter(e => e.contact_id !== contact.contact_id));
    } else {
      let selected = [...selected_contacts];
      selected.push(contact);
      setSelectedContacts(selected);
    }
  };

  const hasLink = (contact) => {
    return links.findIndex(elem => elem.contact_id === contact.contact_id) > -1;
  };

  const isContactSelected = (contact) => {
    return selected_contacts.findIndex(elem => elem.contact_id === contact.contact_id) > -1;
  }

  const selectUser = (user) => {
    let i = selected_users.findIndex(elem => elem.user_id === user.user_id);
    if (i > -1) {
      setSelectedUsers(selected_users.filter(e => e.user_id !== user.user_id));
    } else {
      let selected = [...selected_users];
      selected.push(user);
      setSelectedUsers(selected);
    }
  };

  const isUserSelected = (user) => {
    return selected_users.findIndex(elem => elem.user_id === user.user_id) > -1;
  }

  const createLinks = () => {
    create_links({
      message: props.message,
      contacts: selected_contacts,
      users: selected_users
    })
    alert("Links created");
    props.hideLinks();
  };

  return (
    <div>
      <div>
        <div><a href="/messages" onClick={showMessages} className="btn btn-primary">Back to Messages</a></div>
        <h3>Links for "{props.message && props.message.title}"</h3>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Contact ID</th><th>URL</th><th>Status</th><th></th>
            </tr>
          </thead>
          <tbody>
          {links &&
            links.map(link => (
              <Link link={link} key={link.link_id}/>
            ))
          }
          </tbody>
        </Table>
      </div>
      <hr/>
        <h5>Create Links</h5>
        <div>Select contacts:</div>
        <ListGroup>
          {contacts.length ? contacts.map((contact) => {
            if (hasLink(contact)) {
                return '';
            }
              return <ListGroup.Item key={contact.contact_id} onClick={e => {e.preventDefault(); selectContact(contact)}} active={isContactSelected(contact)} action>{contact.contact_id}: {contact.contact_data.phone}</ListGroup.Item>
          }) : (<div><strong>Create a contact before you can create a link.</strong></div>)}
        </ListGroup>
        <div>Select users to notify:</div>
        <ListGroup>
          {users.length && users.map((user) => {
              return <ListGroup.Item key={user.user_id} onClick={e => {e.preventDefault(); selectUser(user)}} active={isUserSelected(user)} action>{user.first_name} {user.last_name}: {user.phone}</ListGroup.Item>
          })}
        </ListGroup>
      <Button variant="primary" onClick={createLinks}>Create Links</Button>
    </div>
  );
}
