import {doPost} from './Base';


const setPassword = async (password, code=null) => {
  let params = {'password': password};
  if (code) {
      params['code'] = code;
  }
  console.log(params);
  return await doPost('/set_password', params);
};

const resetPassword = async (username) => {
  return await doPost('/reset_password', {username: username});
};


export {setPassword, resetPassword};
export default setPassword;
