import React, {useState, useEffect} from 'react';

import { Link, withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useStoreActions, useStoreState } from "easy-peasy";

function LogIn(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const logIn = useStoreActions(actions => actions.logIn);
  const is_logged_in = useStoreState(state => state.is_logged_in);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (is_logged_in) {
      props.history.push("/");
    }
  }, [is_logged_in, props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();
    logIn({username: username, password: password})
    .then(response => {
      if (response.success) {
        if (response.browser_verified) {
          props.history.push("/");
        } else {
          props.history.push("/log_in_2fa");
        }
      } else if (response.errors) {
        window.alert(response.errors);
      }
    });
  };

  return (
    <>

      {/*<div className="row pt-5 mt-5">
        <div className="col-12">
          <h1 className="text-center">Log In</h1>
        </div>
      </div>*/}
      <div className="container py-5">
      <Form id="signup" className="row d-flex justify-content-center mt-5 pt-5 mx-3" onSubmit={handleSubmit}>
        <div className="col-12 col-md-6 mx-auto bg-white shadow-green rounded py-3 m-3 m-sm-0">
          <Form.Group className="col-12">
            <Form.Label>Email or Phone</Form.Label>
            <Form.Control type="input" onChange={(e) => setUsername(e.target.value)} value={username}/>
          </Form.Group>
          <Form.Group className="col-12">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} value={password}/>
          </Form.Group>
          <div className="col-12">
            <Button type="submit" variant="success btn-lg w-100 text-uppercase mt-4">Log In</Button>
          </div>
          <div className="col-12">
              <Link to="/forgot_password" className="text-success">Forgot Password?</Link>
          </div>
        </div>
      </Form>
      </div>
    </>
  );
}

export default withRouter(LogIn);
